import { DEFAULT_DATA } from "../../library/Constants";
import { FETCH_CHECKOUTS, FETCH_CHECKOUT_DETAILS } from "../action/actionTypes";

const initialState = {
    checkouts: DEFAULT_DATA,
    checkoutDetails:[]
};

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CHECKOUTS:
            return {
                ...state,
                checkouts: action.payload
            };
        case FETCH_CHECKOUT_DETAILS:
            return {
                ...state,
                checkoutDetails: [action.payload]
            }
        default:
            return state;
    }
};

export default checkoutReducer;

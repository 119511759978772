import { FETCH_WORKFLOW, FETCH_PREVIEW_WORKFLOW } from "../action/actionTypes";
import {DEFAULT_DATA} from '../../library/Constants';

const initialState = {
  workflow: DEFAULT_DATA,
  previewWorkflow: DEFAULT_DATA 
};

const workflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKFLOW:
      return {
        ...state,
        workflow: action.payload
      };
      case FETCH_PREVIEW_WORKFLOW:
      return {
        ...state,
        previewWorkflow: action.payload
      };
    default: 
      return state;
  }
};

export default workflowReducer;

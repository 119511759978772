import { DEFAULT_DATA } from "../../library/Constants";
import { FETCH_STATES, USER_STATES_LIST } from "../action/actionTypes";

const initialState = {
  states: DEFAULT_DATA,
  userStatesList: DEFAULT_DATA
};

const stateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STATES:
      return {
        ...state,
        states: action.payload
      };
    case USER_STATES_LIST:
      return {
        ...state,
        userStatesList: action.payload
      }
    default:
      return state;
  }
};

export default stateReducer;

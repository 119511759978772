import { LOADING_START, LOADING_END } from "../../../redux/action/actionTypes";
import { FETCH_NOTIFICATION, NOTIFICATION_COUNT } from './actionTypes';
import { REQUEST_METHOD, NOTIFICATION_COLOUR, DEFAULT_PAGE_NUMBER, DEFAULT_READ_DATA } from '../../../library/Constants';
import { fetchApi, getPageNumberByItemCount } from '../../../library/utils';
import { setFlashNotification } from '../../../redux/action/uiAction';

export function fetchNotificationsCount(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `/notifications/count/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: NOTIFICATION_COUNT, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}

export function fetchNotifications(accessToken, read = DEFAULT_READ_DATA, pageNumber = DEFAULT_PAGE_NUMBER) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `/notifications/?read=${read}&page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_NOTIFICATION, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}

export function markAsReadNotification(accessToken, id, itemCount, pageNumber, read = DEFAULT_READ_DATA) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        let body = { "read": "true" }
        fetchApi(
            `/notifications/${id}`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch(fetchNotifications(accessToken, read = DEFAULT_READ_DATA, getPageNumberByItemCount(itemCount, pageNumber)));
                dispatch(fetchNotificationsCount(accessToken));
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body
        )
    };
}

import { FETCH_DOCUMENT_TYPE, FETCH_DOCUMENT_TYPE_META_DATA } from "./actionTypes";
import { DEFAULT_DATA } from '../../../library/Constants';

const initialState = {
    document_type: DEFAULT_DATA,
    documentTypeMetaData: DEFAULT_DATA
};

const documentTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DOCUMENT_TYPE:
            return {
                ...state,
                document_type: action.payload
            };
        case FETCH_DOCUMENT_TYPE_META_DATA:
            return {
                ...state,
                documentTypeMetaData: action.payload
            }
        default:
            return state;
    }
};

export default documentTypeReducer;

export default [
    {
      _tag: 'CSidebarNavItem',
      name: 'Dashboard',
      to: '/dashboard',
      icon: 'cil-home',
      id: 'dashboard',
    },
    {
      _tag: 'CSidebarNavDropdown',
      name: 'Reports',
      route: '/reports',
      id: 'reports',
      icon: 'cil-shield-alt',
      _children: [
        {
          _tag: 'CSidebarNavItem',
          name: 'ActivityLogs',
          to: '/reports/activity_logs/',
          id: 'ActivityLogs',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Document Compress Log',
          to: '/reports/compress_log',
          id: 'compressLog',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Watch Folder Log',
          to: '/reports/watch_log',
          id: 'watchLog',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Documents Log',
          to: '/reports/documents_log',
          id: 'DocumentsLog',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Duplicate Log',
          to: '/reports/duplicate_log',
          id: 'duplicateLog',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'DocumentEvent Log',
          to: '/reports/documentevent_log',
          id: 'documenteventLog',
        },
        {
          _tag: 'CSidebarNavItem',
          name: 'Corrupt File Log',
          to: '/reports/corruptfile_log',
          id: 'corruptfileLog',
        },
      ]
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'File Explorer ',
      to: '/fileexplorer',
      icon: 'cil-file',
      id: 'file_explorer',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Utility ',
      to: '/utility',
      icon: 'cil-shield-alt',
      id: 'utility',
    },
  ]
  
import { FETCH_GROUPS } from "../action/actionTypes";
import {DEFAULT_DATA} from "../../library/Constants";
const initialState = {
    groups: DEFAULT_DATA
};

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GROUPS:
            return {
                ...state,
                groups: action.payload
            };
        default:
            return state;
    }
};

export default groupsReducer;

export const BASEURL = "https://backend-dms.mgmmin.in/api";
// export const BASEURL = "http://192.168.0.176:8000/api";
// export const BASEURL = "http://192.168.29.21:8000/api";

export const REQUEST_METHOD = {
    GET: "GET",
    PUT: "PUT",
    POST: "POST",
    PATCH: "PATCH",
    DELETE: "DELETE"
}

export const RESPONSE_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    INTERNAL_SERVER_ERROR: 500
}

export const ITEM_PER_PAGE_LIST = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_READ_DATA = "False";
export const DEFAULT_DATA = {
    count: 0,
    next: null,
    previous: null,
    results: []
}

export const NOTIFICATION_COLOUR = {
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    DANGER: 'danger',
    PRIMARY: "primary",
    SECONDARY: "secondary",
    LIGHT: "light",
    DARK: "dark"
}

export const RESPONSE_TYPE = {
    JSON: 'json',
    NULL: null,
    BLOB: 'blob'
}

export const APP_LABEL = {
    DOCUMENTS: 'documents'
}

export const MODEL = {
    DOCUMENT: 'document'
}

export const SEARCH_MODEL = {
    user: 'auth.User'
}

export const TIME_ZONE = [
    {
        value: 'Asia/Kolkata'
    }

]
export const METADATA_FIELDS = {
    DATE: 'date',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    DROPDOWN: 'dropdown',
    TEXTBOX: 'textbox'
}

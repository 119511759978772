import { DEFAULT_DATA } from "../../library/Constants";
import { FETCH_TRANSITIONS } from "../action/actionTypes";

const initialState = {
    transition: DEFAULT_DATA
};

const transitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSITIONS:
      return {
        ...state,
        transition: action.payload
      };
    default:
      return state;
  }
};

export default transitionReducer;

import {
    FETCH_DOCUMENT_LIST, FETCH_DOCUMENT_DATA, FETCH_DOCUMENT_VERSIONS, FETCH_BOOKMARKS_LIST,
    SHOW_WORKFLOW, FETCH_DOCUMENT_PAGES, FETCH_DOCUMENT_COMMENTS, UPDATE_BLOB_URL_WITH_INDEX,
    FETCH_PAGE_OCR_CONTENT, SHOW_TRASH_DOCUMENTS, RECENT_DOCUMENT_LIST, STATE_DOCUMENT_LIST, FETCH_SHAREDLINKS,
    FETCH_DOCUMENT_LOG, FETCH_DOCUMENT_META_DATA, BLOB_DATA, SHOW_WORKFLOW_LOG, FETCH_DOCUMENTS_BY_DOCUMENT_TYPE,
    FETCH_BOOKMARKS
} from "../action/actionTypes";
import { DEFAULT_DATA } from '../../library/Constants';
const initialState = {
    documentList: DEFAULT_DATA,
    stateDocumentList: DEFAULT_DATA,
    documentData: undefined,
    documentVersions: DEFAULT_DATA,
    documentPages: [],
    documentComments: [],
    documentWorkflow: DEFAULT_DATA,
    PageOCR: undefined,
    trashedDocument: DEFAULT_DATA,
    recentDocumentList: DEFAULT_DATA,
    documentLog: DEFAULT_DATA,
    documentMetadata: DEFAULT_DATA,
    documentBlob: '',
    documentDataByDocumentType: DEFAULT_DATA,
    documentWorkflowLogs: DEFAULT_DATA,
    bookmarks: [],
    bookmarklist: [],
    sharedlinkdata: DEFAULT_DATA,
};

const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SHAREDLINKS:
            return {
                ...state,
                sharedlinkdata: action.payload
            };
        case SHOW_WORKFLOW_LOG:
            return {
                ...state,
                documentWorkflowLogs: action.payload
            }
        case FETCH_DOCUMENT_LIST:
            return {
                ...state,
                documentList: action.payload
            };
        case STATE_DOCUMENT_LIST:
            return {
                ...state,
                stateDocumentList: action.payload
            }
        case FETCH_DOCUMENTS_BY_DOCUMENT_TYPE:
            return {
                ...state,
                documentDataByDocumentType: action.payload
            }
        case FETCH_DOCUMENT_DATA:
            return {
                ...state,
                documentData: action.payload
            };
        case FETCH_DOCUMENT_VERSIONS:
            return {
                ...state,
                documentVersions: action.payload
            }
        case FETCH_DOCUMENT_PAGES:
            return {
                ...state,
                documentPages: action.payload
            }
        case FETCH_DOCUMENT_COMMENTS:
            return {
                ...state,
                documentComments: action.payload
            }
        case SHOW_WORKFLOW:
            return {
                ...state,
                documentWorkflow: action.payload
            }
        case UPDATE_BLOB_URL_WITH_INDEX:
            let documentPages = { ...state.documentPages }
            const { index, url } = action.payload
            documentPages.results[index]["blob_image_url"] = url;
            return {
                ...state,
                documentPages: documentPages
            }
        case FETCH_PAGE_OCR_CONTENT:
            return {
                ...state,
                PageOCR: action.payload
            }
        case SHOW_TRASH_DOCUMENTS:
            return {
                ...state,
                trashedDocument: action.payload
            };
        case RECENT_DOCUMENT_LIST:
            return {
                ...state,
                recentDocumentList: action.payload

            }
        case FETCH_DOCUMENT_LOG:
            return {
                ...state,
                documentLog: action.payload
            }
        case FETCH_DOCUMENT_META_DATA:
            return {
                ...state,
                documentMetadata: action.payload
            }
        case BLOB_DATA:
            return {
                ...state,
                documentBlob: action.payload
            }
        case FETCH_BOOKMARKS:
            return {
                ...state,
                bookmarks: action.payload
            }
        case FETCH_BOOKMARKS_LIST:
            return {
                ...state,
                bookmarklist: action.payload
            }
        default:
            return state;
    }
};

export default documentsReducer;

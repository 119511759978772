import { SET_SIDEBAR, FETCH_STATS, FLASH_NOTIFICATION, RELOAD_DATA_TABLE, FETCH_DISK_USAGE } from '../action/actionTypes';

const initialState = {
    sidebarShow: 'responsive',
    flashNotification: false,
    statsData: {},
    reloadDataTable: false,
    diskUsage: {
        "total": 0,
        "used": 0,
        "free": 0
    }
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIDEBAR:
            return {
                ...state,
                sidebarShow: action.payload
            };
        case FLASH_NOTIFICATION:
            return {
                ...state,
                flashNotification: action.payload
            };
        case FETCH_STATS:
            return {
                ...state,
                statsData: action.payload
            }
        case RELOAD_DATA_TABLE:
            return {
                ...state,
                reloadDataTable: action.payload
            }
        case FETCH_DISK_USAGE:
            return {
                ...state,
                diskUsage: action.payload
            }
        default:
            return state;
    }
};

export default uiReducer;

import React, { Component } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import history from './history';
import '../scss/style.scss';
import '../../src/index.css'
import IdleTimer from '../IdleTimer'

// Containers
import TheLayout from '../containers/TheLayout';
import DocumentPreview from "../views/pages/documents/Docpreview";

//pages
import Login from '../views/pages/login/Login';
// const DocumentPrevirw = React.lazy(() => import('../views/pages/documents/Docpreview'));

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeout: true
        }
    }

    componentDidMount = () => {
        const timer = new IdleTimer({
            timeout: 10, //expire after 10 seconds
            onTimeout: () => {
                this.setState({ timeout: false })
            },
            onExpired: () => {
                //do something if expired on load
                this.setState({ timeout: true })
            }
        });

        return () => {
            timer.cleanUp();
        };
    }
    gotoLogin=()=>{
        localStorage.removeItem('userData')
        alert("Token Expired")
    }
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/preview/:encodedLink" name="Preview" render={props => <DocumentPreview/>} />
                    <Route exact path="/login" name="Landing Page" render={props => this.props.accessToken !== undefined ? <Redirect to="/dashboard" /> : <Login {...props} />} />
                    <Route exact path="/login" name="Landing Page" render={props => <Login {...props} />} />
                    {this.state.timeout ? <Route path="/" name="Home" render={props => this.props.accessToken === undefined ? <Redirect to="/login" /> : <TheLayout {...props} />} /> : this.gotoLogin()}
                    <Route render={props => <Login {...props} />} />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = state => {
    return {
        accessToken: state.userReducer.accessToken,
        language: state.languageTypeReducer.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import { FETCH_META_DATA_TYPES } from "../action/actionTypes";
import { DEFAULT_DATA } from '../../library/Constants';

const initialState = {
    metaDataTypes: DEFAULT_DATA
};

const setupReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_META_DATA_TYPES:
            return {
                ...state,
                metaDataTypes: action.payload
            };
        default:
            return state;
    }
};

export default setupReducer;

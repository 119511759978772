import React, { Component } from 'react';
import { CBadge, CDropdown, CDropdownToggle } from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { fetchNotificationsCount } from '../views/pages/notification/action';
import { connect } from 'react-redux';

class TheHeaderDropdownNotif extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.props.getNotificationsCount(this.props.accessToken);
  }

  render() {
    return (
      <CDropdown inNav className="c-header-nav-item mx-2" >
        <CDropdownToggle className="c-header-nav-link" caret={false}
          to="/notification/"
          onClick={() => { this.props.getNotificationsCount(this.props.accessToken) }}
        >
          <CIcon name="cil-bell" />
          <CBadge shape="pill" color="danger">{this.props.notificationCount.count}</CBadge>
        </CDropdownToggle>
      </CDropdown>
    );
  }
}


const mapStateToProps = state => {
  return {
    accessToken: state.userReducer.accessToken,
    loading: state.loadingReducer.loading,
    notificationCount: state.notificationReducer.notificationCount
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getNotificationsCount: (accessToken) => dispatch(fetchNotificationsCount(accessToken)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TheHeaderDropdownNotif);

import { DEFAULT_DATA } from "../../library/Constants";
import { AUTHORIZATION, FETCH_PROFILE, FETCH_USER_CREATION, FAILED_USER_CREATION_DATA, FETCH_ASSIGN_DOCUMENT, FETCH_USERS_DOCUMENT, FETCH_DOCUMENTS_LOG_WEEK } from "../action/actionTypes";

const initialState = {
  accessToken: [],
  profile: {},
  userList: DEFAULT_DATA,
  assignDocument: DEFAULT_DATA,
  usersDocument: DEFAULT_DATA,
  showFailedUserCreationData: [],
  documentCountStatsOfWeek: DEFAULT_DATA,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZATION:
      return {
        ...state,
        accessToken: action.payload
      };
    case FAILED_USER_CREATION_DATA:
      return {
        ...state,
        showFailedUserCreationData: action.payload
      }
    case FETCH_PROFILE:
      return {
        ...state,
        profile: action.payload
      };
    case FETCH_USER_CREATION:
      return {
        ...state,
        userList: action.payload
      };
    case FETCH_ASSIGN_DOCUMENT:
      return {
        ...state,
        assignDocument: action.payload
      };
    case FETCH_USERS_DOCUMENT:
      return {
        ...state,
        usersDocument: action.payload
      };
    case FETCH_DOCUMENTS_LOG_WEEK:
      return {
        ...state,
        documentCountStatsOfWeek: action.payload
      }
    default:
      return state;
  }
};

export default userReducer;

export const SET_SIDEBAR = 'SET_SIDEBAR';
export const LOADING_START = 'LOADING_START';
export const LOADING_END = 'LOADING_END';
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_DOCUMENT_LIST = 'FETCH_DOCUMENT_LIST';
export const FETCH_DOCUMENT_DATA = 'FETCH_DOCUMENT_DATA';
export const FETCH_DOCUMENT_VERSIONS = 'FETCH_DOCUMENT_VERSIONS';
export const FETCH_DOCUMENT_PAGES = 'FETCH_DOCUMENT_PAGES';
export const FETCH_DOCUMENT_LOG = 'FETCH_DOCUMENT_LOG';
export const FETCH_USER_CREATION = 'FETCH_USER_CREATION';
export const FETCH_WORKFLOW = 'FETCH_WORKFLOW';
export const FETCH_STATES = 'FETCH_STATES';
export const FETCH_TRANSITIONS = 'FETCH_TRANSITIONS';
export const FETCH_WORKFLOW_DOCUMENT_TYPE = 'FETCH_WORKFLOW_DOCUMENT_TYPE';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_TAGS = 'FETCH_TAGS';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const DOCUMENT_ID = 'DOCUMENT_ID';
export const DOCUMENT_ID_TAGS = 'DOCUMENT_ID_TAGS';
export const FETCH_DOCUMENT_COMMENTS = 'FETCH_DOCUMENT_COMMENTS';
export const SHOW_TRASH_DOCUMENTS = 'SHOW_TRASH_DOCUMENTS';
export const SHOW_WORKFLOW = 'SHOW_WORKFLOW';
export const SHOW_WORKFLOW_LOG = 'SHOW_WORKFLOW_LOG';
export const FETCH_CHECKOUTS = 'FETCH_CHECKOUTS';
export const FETCH_CHECKOUT_DETAILS = 'FETCH_CHECKOUT_DETAILS';
export const FETCH_SEARCH_MODELS = 'FETCH_SEARCH_MODELS';
export const FETCH_SEARCH_DATA = 'FETCH_SEARCH_DATA';
export const FETCH_ADVANCE_SEARCH_DATA = 'FETCH_ADVANCE_SEARCH_DATA';
export const UPDATE_BLOB_URL_WITH_INDEX = "UPDATE_BLOB_URL_WITH_INDEX";
export const FETCH_PAGE_OCR_CONTENT = "FETCH_PAGE_OCR_CONTENT";
export const FETCH_DOCUMENT_FILES = 'FETCH_DOCUMENT_FILES';
export const FLASH_NOTIFICATION = 'FLASH_NOTIFICATION';
export const RECENT_DOCUMENT_LIST = 'RECENT_DOCUMENT_LIST';
export const CLEAR_RECENT_DOC_LIST = 'CLEAR_RECENT_DOC_LIST';
export const FETCH_META_DATA_TYPES = 'FETCH_META_DATA_TYPES';
export const SHOW_AVAILABLE_PERMISSIONS = 'SHOW_AVAILABLE_PERMISSIONS';
export const SHOW_PERMISSIONS_LIST = 'SHOW_PERMISSIONS_LIST';
export const STAGING_FOLDER_DATA = 'STAGING_FOLDER_DATA';
export const STAGING_FILES_DATA = 'STAGING_FILES_DATA';
export const FETCH_DOCUMENT_META_DATA = 'FETCH_DOCUMENT_META_DATA';
export const GET_ACL_ID = 'GET_ACL_ID';
export const SEARCH_FILTER_DATA = 'SEARCH_FILTER_DATA';
export const FETCH_STATS = 'FETCH_STATS';
export const BLOB_DATA = 'BLOB_DATA';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_FOR_ROLE = 'FETCH_PERMISSIONS_FOR_ROLE';
export const FETCH_DOCUMENTS_BY_DOCUMENT_TYPE = 'FETCH_DOCUMENTS_BY_DOCUMENT_TYPE';
export const FETCH_ASSIGN_DOCUMENT = 'FETCH_ASSIGN_DOCUMENT';
export const FETCH_USERS_DOCUMENT = 'FETCH_USERS_DOCUMENT';
export const FETCH_SUBSCRIPTION = 'FETCH_SUBSCRIPTION';
export const FETCH_SUBSCRIBED = 'FETCH_SUBSCRIBED';
export const FETCH_ADVANCED_SEARCH_URL = 'FETCH_ADVANCED_SEARCH_URL';
export const FETCH_SEARCH_URL = 'FETCH_SEARCH_URL';
export const FETCH_EVENT = 'FETCH_EVENT';
export const FETCH_DOCUMENT_SUBSCRIPTION = 'FETCH_DOCUMENT_SUBSCRIPTION';
export const RELOAD_DATA_TABLE = 'RELOAD_DATA_TABLE';
export const FETCH_PREVIEW_WORKFLOW = 'FETCH_PREVIEW_WORKFLOW';
export const FETCH_BOOKMARKS = 'FETCH_BOOKMARKS';
export const STATE_DOCUMENT_LIST = 'STATE_DOCUMENT_LIST';
export const USER_STATES_LIST = 'USER_STATES_LIST';
export const FAILED_USER_CREATION_DATA = 'FAILED_USER_CREATION_DATA';
export const FETCH_SHAREDLINKS = 'FETCH_SHAREDLINKS';
export const AUTHORIZATION = 'AUTHORIZATION';
export const FETCH_DISK_USAGE = 'FETCH_DISK_USAGE';
export const FETCH_BOOKMARKS_LIST = 'FETCH_BOOKMARKS_LIST';
export const FETCH_DOCUMENTS_LOG_WEEK = 'FETCH_DOCUMENTS_LOG_WEEK';
export const FETCH_ALL_ROLES = 'FETCH_ALL_ROLES';

// Bookmark Action Types
export const FETCH_BOOKMARKS_SETUP = "FETCH_BOOKMARKS_SETUP"

export const AUTO_NOTIFICATIONS = 'AUTO_NOTIFICATIONS';
export const MAIL_NOTIFICATIONS = 'MAIL_NOTIFICATIONS';
export const DOCUMENT_TYPE_ASSIGN_USER = 'DOCUMENT_TYPE_ASSIGN_USER';
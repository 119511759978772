import { LOADING_START, LOADING_END, RELOAD_DATA_TABLE } from '../../../redux/action/actionTypes';
import { FETCH_DOCUMENT_TYPE, FETCH_DOCUMENT_TYPE_META_DATA } from './actionTypes';
import { REQUEST_METHOD, DEFAULT_PAGE_NUMBER, NOTIFICATION_COLOUR, RESPONSE_TYPE } from '../../../library/Constants';
import { setFlashNotification } from '../../../redux/action/uiAction';
import { fetchApi } from '../../../library/utils';

export function fetchDocumentType(accessToken, pageNumber = DEFAULT_PAGE_NUMBER) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `/document_types/?page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_DOCUMENT_TYPE, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}

export function createDocumentType(accessToken, label, trashTimeUnit, trashTimePeriod, deleteTimeUnit, deleteTimePeriod) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        let body = {
            "label": label, "delete_time_period": parseInt(deleteTimePeriod), "delete_time_unit": deleteTimeUnit,
            "trash_time_period": parseInt(trashTimePeriod), "trash_time_unit": trashTimeUnit
        };
        fetchApi(
            `/document_types/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setFlashNotification('Created Document Type Successfully', NOTIFICATION_COLOUR.SUCCESS))
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body)
    };
}

export function updateDocumentType(accessToken, label, id, trashTimeUnit, trashTimePeriod, deleteTimeUnit, deleteTimePeriod) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        let body = {
            "delete_time_period": deleteTimePeriod,
            "delete_time_unit": deleteTimeUnit,
            "label": label,
            "trash_time_period": trashTimePeriod,
            "trash_time_unit": trashTimeUnit
        }
        fetchApi(
            `/document_types/${id}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch(setFlashNotification('Successfully Updated', NOTIFICATION_COLOUR.SUCCESS))
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body);
    };
}

export function DeleteDocumentType(accessToken, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        fetchApi(
            `/document_types/${id}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (success) => {
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, RESPONSE_TYPE.NULL, true, RESPONSE_TYPE.NULL)
    };
}

export function fetchDocumentTypeMetaData(accessToken, documentTypePk) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `/document_types/${documentTypePk}/metadata_types/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_DOCUMENT_TYPE_META_DATA, payload: successResponse });
                dispatch({ type: LOADING_END })
            }, (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
    }
}

export function updateDocumentTypeMetaData(accessToken, documentTypePk, metadataTypePk, required) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        let body = { "required": required }
        fetchApi(
            `/document_types/${documentTypePk}/metadata_types/${metadataTypePk}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch(setFlashNotification('Metadata Type updated to document type', NOTIFICATION_COLOUR.SUCCESS));
                dispatch(fetchDocumentTypeMetaData(accessToken, documentTypePk))
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body
        )
    }
}

export function addDocumentTypeMetaData(accessToken, documentTypePk, metadataTypePk, required) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        let body = { metadata_type_pk: metadataTypePk, required: required }
        fetchApi(
            `/document_types/${documentTypePk}/metadata_types/`,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(setFlashNotification('Metadata Type Added to document type', NOTIFICATION_COLOUR.SUCCESS));
                dispatch(fetchDocumentTypeMetaData(accessToken, documentTypePk))
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body
        )
    }
}

export function deleteDocumentTypeMetaData(accessToken, documentTypePk, metadataTypePk) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `/document_types/${documentTypePk}/metadata_types/${metadataTypePk}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(setFlashNotification('Metadata Type deleted from document type', NOTIFICATION_COLOUR.SUCCESS));
                dispatch(fetchDocumentTypeMetaData(accessToken, documentTypePk))
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, RESPONSE_TYPE.NULL, true, RESPONSE_TYPE.NULL
        )
    }
}

import { FETCH_SEARCH_MODELS, FETCH_SEARCH_DATA, SEARCH_FILTER_DATA, FETCH_ADVANCE_SEARCH_DATA,FETCH_ADVANCED_SEARCH_URL,FETCH_SEARCH_URL } from "../action/actionTypes";
import { DEFAULT_DATA } from '../../library/Constants';
const initialState = {
    documentsearchmodels: [],
    documentsearchdata: DEFAULT_DATA,
    documentadvancesearchdata: DEFAULT_DATA,
    searchFilters: [],
    advancesearchurl:DEFAULT_DATA,
    advancesearchurldata:DEFAULT_DATA
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SEARCH_MODELS:
            return {
                ...state,
                documentsearchmodels: action.payload
            };
        case FETCH_SEARCH_DATA:
            return {
                ...state,
                documentsearchdata: action.payload
            };
        case FETCH_ADVANCE_SEARCH_DATA:
            return {
                ...state,
                documentadvancesearchdata: action.payload
            };
        case SEARCH_FILTER_DATA:
            return {
                ...state,
                searchFilters: action.payload
            };
        case FETCH_ADVANCED_SEARCH_URL:
            return {
                ...state,
                advancesearchurl: action.payload
        };
        case FETCH_SEARCH_URL:
            return {
                ...state,
                advancesearchurldata: action.payload
        };
        default:
            return state;
    }
};

export default searchReducer;

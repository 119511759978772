import React from 'react';
import { CModal, CModalBody, CSpinner } from '@coreui/react';

export default function LoadingModal(props) {
    return (
        <div>
            <CModal show={props.loading} centered={true} closeOnBackdrop={false} >
                <CModalBody className="text-center ">
                    <h3>{props.language.pleaseWait}</h3>
                    <CSpinner color="warning" style={{ width: '4rem', height: '4rem' }} />
                </CModalBody>
            </CModal>
        </div>
    );
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CCol, CFormGroup, CInput, CLabel, CSelect } from '@coreui/react';
import { METADATA_FIELDS } from '../../../../library/Constants';
import { removeArrayElement, convertStringToArray } from '../../../../library/utils';

class DynamicFieldHandler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metaDataTypes: [],
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.metaDataTypes !== this.state.metaDataTypes) {
            this.props.updateChange(this.state.metaDataTypes);
        }
    }

    switchFieldLabelLanguage(item) {
        if (this.props.profile.locale_profile['language'] === 'en' || item.metadata_type.label_hindi === null) {
            return <span>{item.metadata_type.label} 
            {/* {item.required ? <span style={{ color: 'red' }}>*</span> : null}  */}
            </span>
        } else {
            return <span>{item.metadata_type.label_hindi} 
            {item.required ? <span style={{ color: 'red' }}>*</span> : null}
            </span>
        }
    }

    handleMetaDataTypeChange(value, metaDataTypesPk, type) {
        let data = [...this.state.metaDataTypes];
        let flag = true;
        data.map((row) => {
            if (type === METADATA_FIELDS.CHECKBOX) {
                if (row.metadata_type_pk === metaDataTypesPk) {
                    if (row["value"].includes(value)) {
                        row["value"] = removeArrayElement(row["value"], value)
                    }
                    else {
                        row["value"].push(value)
                    }
                    flag = false;
                }
            }
            else {
                if (row.metadata_type_pk === metaDataTypesPk || row.metaDataTypesPk === metaDataTypesPk) {
                    row["value"] = value
                    flag = false;
                }
            }
        })

        if (flag) {
            data.push({ metadata_type_pk: metaDataTypesPk, value: value })
        }
        this.setState({ metaDataTypes: data })
    }

    renderFieldBasedOnFieldType(item, index) {
        switch (item.metadata_type.field_type) {
            case METADATA_FIELDS.RADIO:
                return (
                    <CCol md="6" key={index}>
                        <CLabel>{this.switchFieldLabelLanguage(item)}</CLabel>
                        <CCol></CCol>
                        {convertStringToArray(item.metadata_type.options, '|').map(data => {
                            return (
                                <div className="custom-control custom-radio custom-control-inline">
                                    <input type={METADATA_FIELDS.RADIO} id={data} name="check" value={data} className="custom-control-input" onClick={() => this.handleMetaDataTypeChange(data, item.metadata_type.id, METADATA_FIELDS.RADIO)} />
                                    <label for={data} className="custom-control-label">{data}</label>
                                </div>
                            )
                        })}
                    </CCol>
                );
            case METADATA_FIELDS.DROPDOWN:
                return (
                    <CCol md="6" key={index}>
                        <CLabel>{this.switchFieldLabelLanguage(item)}</CLabel>
                        <CSelect onChange={(e) => { this.handleMetaDataTypeChange(e.target.value, item.metadata_type.id, METADATA_FIELDS.DROPDOWN) }}>
                            {convertStringToArray(item.metadata_type.options, '|').map(data => {
                                return (
                                    <option value={data}>{data}</option>
                                )
                            })}
                        </CSelect>
                    </CCol>
                );
            case METADATA_FIELDS.CHECKBOX:
                return (
                    <CCol md="6" key={index}>
                        <CLabel>{this.switchFieldLabelLanguage(item)}</CLabel>
                        {convertStringToArray(item.metadata_type.options, '|').map(data => {
                            return (
                                <>
                                    <div className="form-check checkbox">
                                        <input type={METADATA_FIELDS.CHECKBOX} className="form-check-input" value={data} onClick={() => this.handleMetaDataTypeChange(data, item.metadata_type.id, METADATA_FIELDS.CHECKBOX)} />
                                        <label className="form-check-label form-check-label" for={data}>{data}</label>
                                    </div>
                                </>
                            )
                        })}
                    </CCol>
                );
            default:
                return (
                    <CCol md="6" key={index}>
                        <CFormGroup >
                            <CLabel>{this.switchFieldLabelLanguage(item)}</CLabel>
                            <CInput type={item.metadata_type.field_type} placeholder={item.metadata_type.label} 
                            // required={item.required}
                                onChange={(e) => {
                                    {
                                        this.handleMetaDataTypeChange(e.target.value, item.metadata_type.id, METADATA_FIELDS.TEXTBOX);
                                    }
                                }} />
                        </CFormGroup>
                    </CCol>
                );
        }
    }

    render() {
        return (
            <>
                {this.props.documentTypeMetaData.results.length > 0 && this.props.documentTypeMetaData.results.map((item, index) => {
                    return (this.renderFieldBasedOnFieldType(item, index))
                })}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        language: state.languageTypeReducer.language,
        profile: state.userReducer.profile,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicFieldHandler);

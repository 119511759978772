import { DEFAULT_DATA } from "../../library/Constants";
import { STAGING_FOLDER_DATA, STAGING_FILES_DATA } from "../action/actionTypes";

const initialState = {
  stagingData: [],
  stagingFilesData: DEFAULT_DATA
};

const utilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAGING_FOLDER_DATA:
      return {
        ...state,
        stagingData: action.payload
      };
    case STAGING_FILES_DATA:
      return {
        ...state,
        stagingFilesData: action.payload
      }
    default:
      return state;
  }
};

export default utilityReducer;

import { BASEURL, REQUEST_METHOD, RESPONSE_CODE, RESPONSE_TYPE, DEFAULT_DATA, TIME_ZONE } from './Constants';
import { LANGUAGE } from '../language/index';
import { ITEM_PER_PAGE_LIST } from './Constants';
import history from '../router/history';

export function getRequestHeader(accessToken) {
    let headers = {
        "Content-Type": "application/json",
        "accept": "application/json"
    };
    if (accessToken !== null) {
        headers["Authorization"] = `Token ${accessToken}`;
    }
    return headers;
}

export const fetchApi = (endPoint, accessToken, method, successCallBack, errorCallBack, body = null, setBaseUrl = true, responseType = RESPONSE_TYPE.JSON) => {
    let options = {
        method: method,
        headers: getRequestHeader(accessToken)
    };

    let requestUrl = endPoint;
    if (setBaseUrl) {
        requestUrl = `${BASEURL}${endPoint}`;
    }

    if (method === REQUEST_METHOD.GET && body !== null) {
        errorCallBack("GET request does not support body")
        return null
    } else if (method !== REQUEST_METHOD.GET) {
        options["body"] = JSON.stringify(body)
    }
    fetch(requestUrl, options)
        .then(response => {
            if (response.status >= 400) {
                return response
            } else {
                switch (responseType) {
                    case RESPONSE_TYPE.JSON:
                        return response.json();
                    case RESPONSE_TYPE.BLOB:
                        return response.blob();
                    case RESPONSE_TYPE.NULL:
                        return DEFAULT_DATA
                }
            }
        })
        .then(responseJson => {
            if (responseJson.type === 'cors') {
                apiErrorHandler(responseJson, errorCallBack, history)
            } else {
                successCallBack(responseJson)
            }
        }).catch(error => {
            errorCallBack(`Something Went Wrong. error : ${error}`)
        })
}

export function apiErrorHandler(response, errorCallBack, history) {
    switch (response.status) {
        case RESPONSE_CODE.INTERNAL_SERVER_ERROR:
            return errorCallBack(`Something Went Wrong, please try again later.`);
        case RESPONSE_CODE.FORBIDDEN:
            errorCallBack(`You do not have permission to perform this action.`)
            history.push("/dashboard")
            // errorCallBack(`You do not have permission to perform this action. Please Login and try Again`)
            // deleteUserDataFromStorage();
            // window.location.reload();
            break;
        case RESPONSE_CODE.UNAUTHORIZED:
            deleteUserDataFromStorage();
            window.location.reload();
            break;
        default:
            getApiErrorMessage(response, errorCallBack);
            break;
    }
}

function getApiErrorMessage(response, errorCallBack) {
    (response.json()).then((data) => {
        if (Array.isArray(data))
            return errorCallBack(data)
        let key = Object.keys(data)
        key = key[0]
        if (typeof data[key] === 'string')
            return errorCallBack(data[key])
        if (data.length > 0) {
            data = data[0]
        }
        let keys = Object.keys(data)
        if (keys.length > 0) {
            keys = keys[0]
        }
        return errorCallBack(`${keys}:${data[keys][0]}`)
    })
}

export async function ConvertPageImageToBlob(accessToken, imageUrl) {
    let requestOptions = {
        method: REQUEST_METHOD.GET,
        headers: getRequestHeader(accessToken),
    };
    let response = await fetch(imageUrl, requestOptions)
    response = await response.blob()
    let blobResponse = await URL.createObjectURL(response);
    return blobResponse;
}

export function setUserDataToStorage(data) {
    localStorage.setItem('userData', JSON.stringify(data))
}

export function getUserDataFromStorage() {
    try {
        const serializedState = localStorage.getItem('userData')
        if (serializedState === null) return undefined
        return JSON.parse(serializedState)
    } catch (e) {
        return undefined
    }
}

export function deleteUserDataFromStorage() {
    localStorage.removeItem('userData');
}

export function csvToJSON(csv) {
    csv = csv.replace('\r', '');
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");
        for (var j = 0; j < headers.length; j++) {
            if (currentline[j] !== undefined && currentline[j] !== "") {
                obj[headers[j]] = currentline[j];
            }
        }
        if (Object.keys(obj).length > 0) {
            result.push(obj);
        }
    }
    return result;
}

export function readFile(file) {
    return new Promise((resolve, reject) => {
        let fr = new FileReader();
        fr.onload = x => resolve(fr.result);
        fr.readAsText(file);
    })
}

export function setTimezone(date, timeZone = TIME_ZONE[0].value) {
    var invdate = new Date(date.toLocaleString('en-US', {
        timeZone: timeZone
    })).toString();
    invdate = invdate.slice(0, invdate.indexOf('G'))
    return invdate;
}

export function getLanguageObject(lang) {
    for (let i = 0; i < LANGUAGE.length; i++) {
        if (lang === LANGUAGE[i].id) {
            return LANGUAGE[i].value
        }
    }
}

export function parseLanguage(navigation, language) {
    if (language !== undefined && language !== []) {
        let navData = [];
        navigation.map(value => {
            if (value._children) {
                var child = [];
                value._children.map(data => {
                    let temp = data.id
                    data.name = language[temp]
                    child.push(data);
                })
                value._children = child;
            }
            if (value.id) {
                value.name = language[value.id]
            }
            navData.push(value);
        })
        return navData
    }
}

export function removeArrayElement(array: array, item: String) {
    var index = array.indexOf(item);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array;
}

export function convertArrayToString(array: array, separator: string) {
    let string = ''
    if (array.length >= 1)
        array.map(value => {
            return (string = string + value + separator)
        })
    string = string.slice(0, string.length - 1)
    return string
}

export function convertStringToArray(data: String, separator: String) {
    let option = []
    let subString = '';
    if (data !== null) {
        for (let i = 0; i < data.length; i++) {
            if (data[i] === separator) {
                i++
                option.push(subString)
                subString = ''
            }
            subString = subString + data[i]
        }
        option.push(subString)
    }
    return option

}

export function bindMultiplePropertyToOneField(array: array, propertiesToBind: array, propertyKey: String) {
    array.map(value => {
        value[propertyKey] = {}
        for (var property in value) {
            if (propertiesToBind.includes(property)) {
                value[propertyKey][property] = value[property]
                delete value[property]
            }
        }

    })
    return array
}

export function updateStringBySeparator(str: String, sourceSeparator: String, targetSeparator: String) {
    let array = convertStringToArray(str, sourceSeparator)
    let string = convertArrayToString(array, targetSeparator)
    return string

}

export function getPageNumberByItemCount(itemCount, currentPageNumber) {
    if (currentPageNumber !== 1 && itemCount === (currentPageNumber - 1) * ITEM_PER_PAGE_LIST + 1) {
        return currentPageNumber - 1
    }
    else {
        return currentPageNumber
    }
}

export function getRandomString(startingThreeLetters) {
    const dateObj = new Date();
    const month = dateObj.getMonth() + 1;
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const output = year + '/' + month + '/' + day
    return startingThreeLetters + output + Math.floor(Math.random() * 100)
}

export function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function getGroupIdFromUserProfile(userProfile) {
    return userProfile.groups === undefined ? 0 : userProfile.groups.length > 0 ? userProfile.groups[0].id : 0
}

export function getISODateOnly(date = new Date()) {
    return date.toISOString().split('T')[0]
}

export const calculateTotalUsers = (data) => {
    var totalUsers = data.value.users_count
    data.child.length > 0 && data.child.map((item, index) => {
        totalUsers += item.users_count
    })
    return totalUsers
}

export const manageText = (text) => {
    var returnedText = ''
    if (text.length < 20) {
        returnedText = text
    }
    else {
        text.split('').map((item, index) => {
            if (index < 20) {
                returnedText += item
            }
        })
        returnedText += '....'
    }

    return returnedText
}

export const convertGroupsIntoSubgroups = (list) => {
    var returnedList = []
    list.map((item, index) => {
        returnedList.push(item.value);
        item.child.length > 0 && item.child.map((val, i) => {
            returnedList.push(val);
        })
    })
    return returnedList
}


export const getGroupWiseRoles = (roles, groupId) => {
    var returned_roles = []
    roles.map((item, index) => {
        item.groups.length > 0 && item.groups.map((group, i) => {
            if (group.id == groupId) {
                returned_roles.push(item)
            }
        })
    })
    return returned_roles
}

export function insertElementAtIndex(array, element, index) {
    // Check if the index is valid
    if (index < 0 || index > array.length) {
        console.log("Invalid index");
        return;
    }

    // Move elements to the right to make space for the new element
    for (let i = array.length; i > index; i--) {
        array[i] = array[i - 1];
    }

    // Insert the element at the specified index
    array[index] = element;

    return array;
}

export const handleNavItemsByPermissions = (navArr, allPermissionNames) => {
    var arr = [...navArr]
    var acl_obj = {
        _tag: 'CSidebarNavDropdown',
        name: 'ACLs',
        route: '/acls',
        id: 'acls',
        icon: 'cil-check-circle',
        _children: []
    }

    var document_obj = {
        _tag: 'CSidebarNavDropdown',
        name: 'Knowledge Repository',
        route: '/base',
        id: 'documents',
        icon: 'cil-layers',
        _children: []
    }

    var setup_obj = {
        _tag: 'CSidebarNavDropdown',
        name: 'Setup',
        route: '/setup',
        id: 'setup',
        icon: 'cil-settings',
        _children: [
            {
                _tag: 'CSidebarNavItem',
                name: 'Mail Notifications',
                to: '/setup/mail_notifications',
                id: 'mailNotifications',
            }
        ]
    }

    var reports_obj = {
        _tag: 'CSidebarNavDropdown',
        name: 'Reports',
        route: '/reports',
        id: 'reports',
        icon: 'cil-shield-alt',
        _children: []
    }

    if (allPermissionNames.includes('documents.document_create')) {
        document_obj._children.push({
            _tag: 'CSidebarNavItem',
            name: 'Upload Document',
            to: '/workarea',
            id: 'upload',
        })
    }
    if (allPermissionNames.includes('documents.document_view')) {
        document_obj._children.push({
            _tag: 'CSidebarNavItem',
            name: 'Search',
            to: '/search',
            id: 'search',
        },
            {
                _tag: 'CSidebarNavItem',
                name: 'Documents',
                to: '/documents',
                id: 'documents',
            })
    }
    if (allPermissionNames.includes('document_states.workflow_view')) {
        document_obj._children.push(
            {
                _tag: 'CSidebarNavItem',
                name: 'State Documents',
                to: '/statedocuments',
                id: 'stateDocuments',
            })
    }
    if (allPermissionNames.includes('documents.document_assign_view')) {
        document_obj._children.push(
            {
                _tag: 'CSidebarNavItem',
                name: 'Assigned Documents',
                to: '/assigneddocuments',
                id: 'assigneddocuments',
            })
    }
    if (allPermissionNames.includes('document_indexing.document_index_delete')) {
        document_obj._children.push({
            _tag: 'CSidebarNavItem',
            name: 'Trash',
            to: '/trash',
            id: 'trash',
        })
    }
    if (allPermissionNames.includes('documents.document_index_view') || allPermissionNames.includes('documents.document_create')) {
        arr = insertElementAtIndex(arr, document_obj, 1)
    }


    if (allPermissionNames.includes('acls.acl_edit')) {
        acl_obj._children.push({
            _tag: 'CSidebarNavItem',
            name: 'Groups',
            to: '/acls/groups',
            id: 'groups',
        })
    }
    if (allPermissionNames.includes("acls.acl_view")) {
        acl_obj._children.push({
            _tag: 'CSidebarNavItem',
            name: 'Roles',
            to: '/acls/roles',
            id: 'roleViewHeader',
        })
    }
    if (allPermissionNames.includes('acls.acl_edit') || allPermissionNames.includes('acls.acl_view')) {
        arr = insertElementAtIndex(arr, acl_obj, 2)
    }

    if (allPermissionNames.includes('documents_types.document_type_view')) {
        setup_obj._children.push({
            _tag: 'CSidebarNavItem',
            name: 'Document Type',
            to: '/setup/document_type',
            id: 'documentType',
        })
    }
    if (allPermissionNames.includes('user_management.user_view')) {
        setup_obj._children.push(
            {
                _tag: 'CSidebarNavItem',
                name: 'Users',
                to: '/setup/userCreation',
                id: 'availableUserHeader',
            })
    }
    if (allPermissionNames.includes('document_states.workflow_view')) {
        setup_obj._children.push(
            {
                _tag: 'CSidebarNavItem',
                name: 'Workflow',
                to: '/setup/workflow',
                id: 'workflow',
            })
    }
    if (allPermissionNames.includes('tags.tag_view')) {
        setup_obj._children.push(
            {
                _tag: 'CSidebarNavItem',
                name: 'Tags',
                to: '/setup/tags',
                id: 'tags',
            })
    }
    if (allPermissionNames.includes('documents.document_bookmark_view')) {
        setup_obj._children.push(
            {
                _tag: 'CSidebarNavItem',
                name: 'set_up_bookmarks',
                to: '/setup/bookmarks',
                id: 'set_up_bookmarks',
            })
    }
    if (allPermissionNames.includes('metadata_setup.metadata_type_view') || allPermissionNames.includes('metadata.metadata_document_view') || allPermissionNames.includes('file_metadata.file_metadata_view')) {
        setup_obj._children.push(
            {
                _tag: 'CSidebarNavItem',
                name: 'Metadata Types',
                to: '/setup/metadatatypes',
                id: 'metadataTypes',
            })
    }

    if (allPermissionNames.includes('documents_types.document_type_view') || allPermissionNames.includes('user_management.user_view') || allPermissionNames.includes('document_states.workflow_view') || allPermissionNames.includes('tags.tag_view') || allPermissionNames.includes('documents.document_bookmark_view') || allPermissionNames.includes('metadata_setup.metadata_type_view')) {
        arr = insertElementAtIndex(arr, setup_obj, 3)
    }


    return arr
}
import { DEFAULT_DATA } from "../../library/Constants";
import { FETCH_WORKFLOW_DOCUMENT_TYPE } from "../action/actionTypes";

const initialState = {
  workflow_document_type: DEFAULT_DATA
};

const workflowDocumentTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKFLOW_DOCUMENT_TYPE:
      return {
        ...state,
        workflow_document_type: action.payload
      };
    default:
      return state;
  }
};

export default workflowDocumentTypeReducer;

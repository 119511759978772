import * as TYPES from './actionTypes';
import { DEFAULT_DATA } from "../../../library/Constants";

const initialState = {
    compressionLog: DEFAULT_DATA,
    watchFolderLog: DEFAULT_DATA,
    documentCountStats: DEFAULT_DATA,
    duplicateLog: DEFAULT_DATA,
    documenteventlog:DEFAULT_DATA,
    corruptfileLog: DEFAULT_DATA,
};

const reportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.FETCH_COMPRESSION_LOG:
            return {
                ...state,
                compressionLog: action.payload
            }
        case TYPES.FETCH_DOCUMENTS_LOG:
            return {
                ...state,
                documentCountStats: action.payload
            }
        case TYPES.FETCH_WATCH_FOLDER_LOG:
            return {
                ...state,
                watchFolderLog: action.payload
            }
        case TYPES.FETCH_DUPLICATE_LOG:
            return {
                ...state,
                duplicateLog: action.payload
            }
        case TYPES.FETCH_DOCUMENTEVENT_LOG:
            return {
                ...state,
                documenteventlog: action.payload
            }
        case TYPES.FETCH_CORRUPT_FILE_LOG:
            return {
                ...state,
                corruptfileLog: action.payload
            }
        default:
            return state;
    }
};

export default reportsReducer;

import { CHANGE_LANGUAGE } from "../action/actionTypes";
import { ENGLISH } from '../../language/english';

const initialState = {
    language: ENGLISH
};

const languageTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload
            };
        default:
            return state;
    }
};

export default languageTypeReducer;

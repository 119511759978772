import { AUTO_NOTIFICATIONS, MAIL_NOTIFICATIONS } from "../action/actionTypes";
import { DEFAULT_DATA } from '../../library/Constants';

const initialState = {
    autoNotifications: DEFAULT_DATA,
    mailNotifications: DEFAULT_DATA
};

const MailNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTO_NOTIFICATIONS:
            return {
                ...state,
                autoNotifications: action.payload
            };
        case MAIL_NOTIFICATIONS:
            return {
                ...state,
                mailNotifications: action.payload
            };
        default:
            return state;
    }
};

export default MailNotificationReducer;

import { SHOW_AVAILABLE_PERMISSIONS, SHOW_PERMISSIONS_LIST,GET_ACL_ID} from "../action/actionTypes";
import { DEFAULT_DATA } from '../../library/Constants';

const initialState = {
    availablePermissionData: [],
    permissionDataPerRole:[],
    getaclid:DEFAULT_DATA
};

const permissionReducers = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_AVAILABLE_PERMISSIONS:
            return {
                ...state,
                availablePermissionData: action.payload
            };

            case SHOW_PERMISSIONS_LIST:
                return {
                    ...state,
                    permissionDataPerRole: action.payload
                };
            case GET_ACL_ID:
            return {
                ...state,
                getaclid: action.payload
            };
        default:
            return state;
    }
};

export default permissionReducers;

import React, { Component } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CForm,
  CRow,
  CFormGroup,
  CInput,
  CLabel,
  CSelect,
  CButton,
  CInputCheckbox,
} from "@coreui/react";
import { connect } from "react-redux";
import { setFlashNotification } from "../../../redux/action/uiAction";
import {
  fetchDocumentType,
  fetchDocumentTypeMetaData,
} from "../documentType/action";
import { fetchAdvanceSearch } from "./SearchAction";
import DynamicFieldHandler from "../common/components/DynamicFieldHandler";

class AdvanceSearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentType: "",
      metaDataTypes: [],
      selectedDocumentType: "",
      selectedDepartmentList: [],
      formdata: "",
      matchAll: true,
      ocrText: "",
      isCheckedR: false,
      isCheckedE: false,
      fromDate: "",
      toDate: "",
      dateRangeArray: [],
    };
  }

  componentDidMount() {
    this.props.fetchDocumentType(this.props.accessToken);
    this.setState({
        selectedDocumentType: "", 
        isCheckedR: false,
        isCheckedE: false,
    })
  }

  onDocumentTypeSelectionChange(index) {
    let data = JSON.parse(index);
    if (data !== "" && this.props.document_type.results.length > 0) {
      let documentTypePk = data.id;
      this.props.fetchDocumentTypeMetaData(
        this.props.accessToken,
        documentTypePk
      );
      this.setState({
        documentType: documentTypePk,
        metaDataTypes: [],
        selectedDocumentType: data.value,
      });
    }
  }

  searchDocument(event) {
    event.preventDefault();
    let query = "";
    var data = [];
    const state = this.state;
  
    // Add match_all query if selected
    if (state.matchAll) {
      query = "_match_all=on&";
    }
  
    // Collect metadata values
    this.state.metaDataTypes.map((row) => {
      if (this.state.selectedDocumentType !== null) {
        data.push(row.value);
      }
    });
  
    // Construct metadata query based on date range selections
    let metadataQuery = "";
    if (this.state.isCheckedR) {
      metadataQuery += `metadata__metadata_type__name=Renewal date&metadata__value=${state.dateRangeArray.join(",")}&`;
    }
    if (this.state.isCheckedE) {
      metadataQuery += `metadata__metadata_type__name=Exp Date&metadata__value=${state.dateRangeArray.join(",")}&`;
    }
  
    // Construct the final query
    const finalQuery = `${query}document_type__label=${state.selectedDocumentType}&metadata__value=${data.join(",")}&${metadataQuery}`;
  
    // Fetch the advanced search results
    if (this.state.ocrText !== "") {
      this.props.fetchAdvanceSearch(
        this.props.accessToken,
        `${finalQuery}versions__version_pages__ocr_content__content=${state.ocrText}`,
        this.props.history
      );
    } else {
      this.props.fetchAdvanceSearch(
        this.props.accessToken,
        finalQuery,
        this.props.history
      );
    }
  }
  

  handleCheckboxChange = (type) => {
    this.setState((prevState) => {
      if (type === "R") {
        return {
          isCheckedR: !prevState.isCheckedR,
          isCheckedE: false,
          fromDate: "",
          toDate: "",
          dateRangeArray: [],
        };
      } else if (type === "E") {
        return {
          isCheckedE: !prevState.isCheckedE,
          isCheckedR: false,
          fromDate: "",
          toDate: "",
          dateRangeArray: [],
        };
      }
    });
  };

  handleDateChange = (event, field) => {
    this.setState({ [field]: event.target.value }, this.updateDateRangeArray);
  };

  updateDateRangeArray = () => {
    const { fromDate, toDate } = this.state;
    if (fromDate && toDate) {
      const start = new Date(fromDate);
      const end = new Date(toDate);
      let dateRangeArray = [];

      for (
        let date = start;
        date <= end;
        date.setDate(date.getDate() + 1)
      ) {
        dateRangeArray.push(new Date(date).toISOString().split("T")[0]);
      }

      this.setState({ dateRangeArray });
    }
  };

  render() {
    console.log("Date Range Array:", this.state.dateRangeArray);
    return (
      <CForm
        action=""
        method="post"
        onSubmit={(event) => this.searchDocument(event)}
        encType="multipart/form-data"
        className="form-horizontal "
      >
        <CRow>
          <CCol xs="12" md="12">
            <h4 className="mb-3"> Search Content</h4>
          </CCol>
          <CCol xs="12" md="12">
            <CCard>
              <CCardBody>
                <CFormGroup row>
                  <CCol md="6">
                    <CLabel>{this.props.language.documentType}</CLabel>
                    <CSelect
                      custom
                      name="select"
                      id="select"
                      onChange={(event) =>
                        this.onDocumentTypeSelectionChange(event.target.value)
                      }
                    >
                      <option value="">{this.props.language.chooseType}</option>
                      {this.props.profile.user_assing !== undefined &&
                        this.props.profile.user_assing.length > 0 &&
                        this.props.profile.user_assing.map((row, index) => {
                          return (
                            <option
                              key={index}
                              value={JSON.stringify({
                                id: row.document_type.id,
                                value: row.document_type.label,
                              })}
                            >
                              {row.document_type.label}
                            </option>
                          );
                        })}
                    </CSelect>
                  </CCol>
                  <CCol xs="12" md="12">
                    <CLabel>{this.props.language.ocr}</CLabel>
                    <CInput
                      type="text"
                      placeholder={this.props.language.searchTextHere}
                      onChange={(event) => {
                        this.setState({ ocrText: event.target.value });
                      }}
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                    {this.state.documentType!=="" && <CCol md="12">
                    <CRow>
                      <DynamicFieldHandler
                        documentTypeMetaData={
                          this.props.documentTypeMetaData
                        }
                        updateChange={(data) =>
                          this.setState({ metaDataTypes: data })
                        }
                      />
                    </CRow>
                  </CCol>}
                  
                  {this.state.documentType!=="" &&this.props.documentTypeMetaData.results.find(
                    (item) => item.metadata_type?.field_type === "date"
                  ) && (
                    <>
                      {!this.state.isCheckedE && (
                        <CCol md="6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            <CLabel>Is Renewal date Range?</CLabel>
                            <input
                              type="checkbox"
                              checked={this.state.isCheckedR}
                              onChange={() =>
                                this.handleCheckboxChange("R")
                              }
                              style={{
                                height: "20px",
                                width: "20px",
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                        </CCol>
                      )}
                      {!this.state.isCheckedR && (
                        <CCol md="6">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "left",
                            }}
                          >
                            <CLabel>Is Exp date Range?</CLabel>
                            <input
                              type="checkbox"
                              checked={this.state.isCheckedE}
                              onChange={() =>
                                this.handleCheckboxChange("E")
                              }
                              style={{
                                height: "20px",
                                width: "20px",
                                marginLeft: "10px",
                              }}
                            />
                          </div>
                        </CCol>
                      )}
                    </>
                  )}
                </CFormGroup>
                <CFormGroup row>
                  {(this.state.isCheckedR || this.state.isCheckedE) && (
                    <>
                      <CCol md="6">
                        <CLabel>From Date</CLabel>
                        <CInput
                          type="date"
                          value={this.state.fromDate}
                          onChange={(event) =>
                            this.handleDateChange(event, "fromDate")
                          }
                        />
                      </CCol>
                      <CCol md="6">
                        <CLabel>To Date</CLabel>
                        <CInput
                          type="date"
                          value={this.state.toDate}
                          onChange={(event) =>
                            this.handleDateChange(event, "toDate")
                          }
                        />
                      </CCol>
                    </>
                  )}
                </CFormGroup>
                <CFormGroup row>
                  <CCol md="12" className="mt-4">
                    <CButton type="submit" size="md" color="success">
                      {this.props.language.searchBtn}
                    </CButton>
                  </CCol>
                </CFormGroup>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CForm>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      accessToken: state.userReducer.accessToken,
      document_type: state.documentTypeReducer.document_type,
      documentTypeMetaData: state.documentTypeReducer.documentTypeMetaData,
      language: state.languageTypeReducer.language,
      profile: state.userReducer.profile,
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchDocumentType: (accessToken) =>
        dispatch(fetchDocumentType(accessToken)),
      setFlashMessage: (message, color) =>
        dispatch(setFlashNotification(message, color)),
      fetchDocumentTypeMetaData: (accessToken, documentTypePk) =>
        dispatch(fetchDocumentTypeMetaData(accessToken, documentTypePk)),
      fetchAdvanceSearch: (accessToken, query, history) =>
        dispatch(fetchAdvanceSearch(accessToken, query, history)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AdvanceSearchPage);

import { LOADING_START, LOADING_END, FETCH_PROFILE, FAILED_USER_CREATION_DATA, FETCH_USER_CREATION, RELOAD_DATA_TABLE, STATE_DOCUMENT_LIST } from '../../../redux/action/actionTypes';
import { fetchApi, apiErrorHandler } from '../../../library/utils';
import { DEFAULT_DATA } from '../../../library/Constants';
import { REQUEST_METHOD, NOTIFICATION_COLOUR, DEFAULT_PAGE_NUMBER, RESPONSE_TYPE, BASEURL } from '../../../library/Constants';
import { setFlashNotification } from '../../../redux/action/uiAction';
import { changeLanguage } from '../../../containers/changeLanguageAction';
import { fetchAllStatesAssignedToCurrentUser } from '../documents/actions/StateDocumentAction'

const API_USERS = '/users/';
const API_USERS_CURRENT = '/users/current/';
const API_USERS_LOCALE = '/users/locale/';
const API_USERS_BULK_REGISTER = '/users/bulk_register/';
const API_ACL_LIST = '/files/acl/list';
const API_ACL_CREATE = '/files/acl/create';
const API_ACL_UPDATE = '/files/acl/update';
export function fetchUsers(accessToken, pageNumber = DEFAULT_PAGE_NUMBER) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_USERS}?page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_USER_CREATION, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}
export function searchUsers(accessToken,type,searchdata) {
    console.log("sdata",type,searchdata)
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `/search/advanced/auth.User?${type}=${searchdata}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_USER_CREATION, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}
export function createUser(accessToken, first_name, last_name, email, username, password, assignGroups, language, timezone) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        let body = {
            first_name: first_name,
            email: email,
            groups_pk_list: assignGroups,
            last_name: last_name,
            password: password,
            username: username,
            locale_profile: { timezone: language, language: timezone }
        }

        fetchApi(
            API_USERS,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
                dispatch(setFlashNotification("User Created successfully", NOTIFICATION_COLOUR.SUCCESS));
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body)
    };
}

export function fetchProfile(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            API_USERS_CURRENT,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_PROFILE, payload: successResponse });
                dispatch({ type: STATE_DOCUMENT_LIST, payload: DEFAULT_DATA });
                dispatch(fetchAllStatesAssignedToCurrentUser(accessToken, successResponse.id))
                if (successResponse.locale_profile.language === '')
                    dispatch(changeLanguage('en'))
                else
                    dispatch(changeLanguage(successResponse.locale_profile.language))
                dispatch({ type: LOADING_END })

            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}
export function getAclData(accessToken, id, data) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_ACL_LIST}?user_id=${id}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                console.log(successResponse)
                // dispatch({ type: FETCH_PROFILE, payload: successResponse });
                // dispatch({ type: STATE_DOCUMENT_LIST, payload: DEFAULT_DATA });
                data(successResponse);
                // dispatch(fetchAllStatesAssignedToCurrentUser(accessToken, successResponse.id))
                dispatch({ type: LOADING_END })
            },
            (error) => {
                console.log(error)
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}
export function createAclData(e, accessToken, obj, modal) {
    e.preventDefault();
    console.log(obj)
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            API_ACL_CREATE,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                console.log(successResponse)
                // dispatch({ type: FETCH_PROFILE, payload: successResponse });
                // dispatch({ type: STATE_DOCUMENT_LIST, payload: DEFAULT_DATA });
                modal();
                dispatch(setFlashNotification(successResponse.message, NOTIFICATION_COLOUR.SUCCESS))
                // dispatch(fetchAllStatesAssignedToCurrentUser(accessToken, successResponse.id))
                dispatch({ type: LOADING_END })
            },
            (error) => {
                console.log(error)
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, obj)
    };
}
export function updateAclData(e, accessToken, obj, modal) {
    e.preventDefault();
    console.log(obj)
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            API_ACL_UPDATE,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                console.log(successResponse)
                dispatch({ type: FETCH_PROFILE, payload: successResponse });
                dispatch({ type: STATE_DOCUMENT_LIST, payload: DEFAULT_DATA });
                modal();
                dispatch(setFlashNotification(successResponse.message, NOTIFICATION_COLOUR.SUCCESS))
                // dispatch(fetchAllStatesAssignedToCurrentUser(accessToken, successResponse.id))
                dispatch({ type: LOADING_END })
            },
            (error) => {
                console.log(error)
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, obj)
    };
}

export function UpdateProfile(accessToken, body, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        fetchApi(
            `${API_USERS}${id}/`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch(fetchProfile(accessToken, id));
                dispatch(setFlashNotification("Profile updated successfully", NOTIFICATION_COLOUR.SUCCESS));
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
            },
            (error) => {
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
                dispatch({ type: LOADING_END })
            }, body)
    };
}

export function DeleteUser(accessToken, id, pass) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        fetchApi(
            `${API_USERS}${id}/`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
                dispatch(setFlashNotification("User Deleted successfully", NOTIFICATION_COLOUR.DANGER));
                pass()
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, null, true, RESPONSE_TYPE.NULL)
    };
}

export function BulkUserRegister(accessToken, userData) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            API_USERS_BULK_REGISTER,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(successResponse.msg, NOTIFICATION_COLOUR.SUCCESS))
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
            },
            (errorResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FAILED_USER_CREATION_DATA, payload: errorResponse })
            },
            userData
        )
    }
}
export function clearReducer(type, value) {
    return function (dispatch) {
        dispatch({ type: type, payload: value })
    }
}
export function updateUserTimezoneAndLanguage(accessToken, id, timezone, language) {
    let body = {
        "user": id,
        "timezone": timezone,
        "language": language
    }
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            API_USERS_LOCALE,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
                dispatch(fetchProfile(accessToken, id));
                dispatch(setFlashNotification("Profile updated successfully", NOTIFICATION_COLOUR.SUCCESS));
            },
            (error) => {
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
                dispatch({ type: LOADING_END })
            },
            body
        )
    }
}

export function createUserProfile(accessToken, file, first_name, last_name, email, username, password, assignGroups, language, timezone) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        let formData = new FormData();
        formData.append("first_name", first_name);
        formData.append("email", email);
        formData.append("groups_pk_list", assignGroups);
        formData.append("last_name", last_name);
        formData.append("password", password);
        formData.append("username", username);
        { (file) ? formData.append("image", file[0], file[0].name) : formData.append("image", '') };
        var requestOptions = {
            method: REQUEST_METHOD.POST,
            headers: { "Authorization": `Token ${accessToken}` },
            body: formData
        };
        fetch(BASEURL + API_USERS, requestOptions)
            .then(response => {
                if (response.status >= 400)
                    return response
                else
                    return response.json();
            })
            .then(responseJson => {
                if (responseJson.type === 'cors') {
                    apiErrorHandler(responseJson, (error) => {
                        dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
                        dispatch({ type: LOADING_END })
                    })
                } else {
                    dispatch({ type: RELOAD_DATA_TABLE, payload: true });
                    dispatch(setFlashNotification("User Created successfully", NOTIFICATION_COLOUR.SUCCESS));
                    window.location.reload();
                }
            })
            .catch(error => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            });
    };
}
export function UploadSignature(accessToken, image, signature, designation) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        let formData = new FormData();
        { (image) ? formData.append("image", image[0], image[0].name) : formData.append("image", '') };
        { (signature) ? formData.append("signature", signature[0], signature[0].name) : formData.append("signature", '') };
        formData.append("designation", designation);
        var requestOptions = {
            method: REQUEST_METHOD.POST,
            headers: { "Authorization": `Token ${accessToken}` },
            body: formData
        };
        fetch(BASEURL + '/users/Profile/images', requestOptions)
            .then(response => response.json())
            .then(successResponse => {
                dispatch({ type: LOADING_END });
                dispatch(setFlashNotification("Profile updated successfully", NOTIFICATION_COLOUR.SUCCESS));
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
                window.location.reload(false)

            }
            )
            .catch(error => {
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
                dispatch({ type: LOADING_END })
            });
    };
}
export function UpdateProfilePicture(accessToken, image, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        let formData = new FormData();
        formData.append("image", image[0], image[0].name);
        var requestOptions = {
            method: REQUEST_METHOD.PATCH,
            headers: { "Authorization": `Token ${accessToken}` },
            body: formData
        };
        fetch(BASEURL + `${API_USERS}${id}/`, requestOptions)
            .then(response => response.json())
            .then(successResponse => {
                dispatch(fetchProfile(accessToken, id));
                dispatch(setFlashNotification("Profile updated successfully", NOTIFICATION_COLOUR.SUCCESS));
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
            }
            )
            .catch(error => {
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
                dispatch({ type: LOADING_END })
            });
    };
}

export function UpdateSignature(accessToken, image, signature, designation) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        dispatch({ type: RELOAD_DATA_TABLE, payload: false });
        dispatch({ type: LOADING_START })
        let formData = new FormData();
        { (image) ? formData.append("image", image[0], image[0].name) : formData.append("image", '') };
        { (signature) ? formData.append("signature", signature[0], signature[0].name) : formData.append("signature", '') };
        formData.append("designation", designation);
        var requestOptions = {
            method: REQUEST_METHOD.PATCH,
            headers: { "Authorization": `Token ${accessToken}` },
            body: formData
        };
        fetch(BASEURL + '/users/Profile/images/details', requestOptions)
            .then(response => response.json())
            .then(successResponse => {
                dispatch(fetchProfile(accessToken));
                dispatch(setFlashNotification("Signature updated successfully", NOTIFICATION_COLOUR.SUCCESS));
                dispatch({ type: RELOAD_DATA_TABLE, payload: true });
                dispatch({ type: LOADING_END })
                window.location.reload(false)
            }
            )
            .catch(error => {
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
                dispatch({ type: LOADING_END })
            });
    };
}

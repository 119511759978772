import { FETCH_PERMISSIONS, FETCH_PERMISSIONS_FOR_ROLE } from "../action/actionTypes";

const initialState = {
    permissions: [],
    availablePermissions: []
};

const allPermissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload
            };
        case FETCH_PERMISSIONS_FOR_ROLE:
            return {
                ...state,
                availablePermissions: action.payload
            };
        default:
            return state;
    }
};

export default allPermissionsReducer;

import React, { Component } from 'react';
import { TheContent, TheSidebar, TheFooter, TheHeader } from './index'
import { connect } from 'react-redux';
import LoadingModal from '../views/base/modal/LoadingModal';
import Confirm from '../views/base/modal/Confirm';
import TheSidebar_bak from './TheSidebar_bak';

class TheLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="c-app c-default-layout ">
        <TheSidebar language={this.props.language}  />
        {/* <TheSidebar_bak language={this.props.language}  /> */}
        <div className="c-wrapper">
          <TheHeader />
          <div className="c-body">
            <TheContent />
            <LoadingModal loading={this.props.loading} language={this.props.language} />
            <Confirm language={this.props.language}/>
          </div>
          <TheFooter />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.loadingReducer.loading,
    language: state.languageTypeReducer.language,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TheLayout);
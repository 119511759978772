import React from 'react'
import { CFooter } from '@coreui/react'
import digiFolioLogo from '../assets/images/digiFolio.png'
import epsumlabsLogo from '../assets/images/logos.png'

const TheFooter = () => {
  let currentYear = new Date().getFullYear();

  return (
    <CFooter fixed={false}>
      <div>
        <span><b>Copyright &copy; <span>{currentYear}</span> </b><a href="https://www.epsumlabs.com/" target="_blank">Epsum Labs Private Limited.</a><b> All rights reserved.</b></span>
      </div>
      <div className="mfs-auto" style={{textAlign:"right"}}>
        <span>
          <b>Powered by : </b>
          <img src={epsumlabsLogo} alt="digifolio logo" className="dashboard-footerLogo"/>
        </span>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)

import { FETCH_NOTIFICATION, NOTIFICATION_COUNT } from './actionTypes';
import { DEFAULT_DATA } from '../../../library/Constants';

const initialState = {
    notifications: DEFAULT_DATA,
    notificationCount: {"count":1},
};

const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTIFICATION:
            return {
                ...state,
                notifications: action.payload
            };
        case NOTIFICATION_COUNT:
            return {
                ...state,
                notificationCount: action.payload
            };
        default:
            return state;
    }
};

export default notificationReducer;

import React from 'react';
import {
    CButton, CCol, CForm, CFormGroup, CLabel, CModal, CModalBody,
    CModalFooter, CModalHeader, CModalTitle
} from '@coreui/react';

export default function Confirm(props) {
    return (
        <CModal show={props.showModal} closeOnBackdrop={false}>
            <CModalHeader >
                <CModalTitle className="text-center">{props.language.confirm}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CForm action="" method="post" encType="multipart/form-data" className="form-horizontal">
                    <CFormGroup row>
                        <CCol xs="12" md="12">
                            <CLabel ><h4>{props.language.areYouSure}</h4></CLabel>
                        </CCol>
                    </CFormGroup>
                </CForm>
            </CModalBody>
            <CModalFooter>
                <CButton color="info" onClick={() => props.onCancelPress()}>{props.language.cancel}</CButton>
                <CButton color="success" onClick={() => props.onYesPress()}>{props.language.yes}</CButton>
            </CModalFooter>
        </CModal>
    );
}

export default [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-home',
    id: 'dashboard',
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Knowledge Repository',
    route: '/base',
    id: 'documents',
    icon: 'cil-layers',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Upload Document',
        to: '/workarea',
        id: 'upload',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Search',
        to: '/search',
        id: 'search',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Search Template',
      //   to: '/searchtemplate',
      //   id: 'searchTemplate'
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Documents',
        to: '/documents',
        id: 'documents',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'State Documents',
        to: '/statedocuments',
        id: 'stateDocuments',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Assigned Documents',
        to: '/assigneddocuments',
        id: 'assigneddocuments',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Trash',
        to: '/trash',
        id: 'trash',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Checkouts',
      //   to: '/checkouts',
      //   id: 'checkouts'
      // },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'ACLs',
    route: '/acls',
    id: 'acls',
    icon: 'cil-check-circle',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Groups',
        to: '/acls/groups',
        id: 'groups',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Roles',
        to: '/acls/roles',
        id: 'roleViewHeader',
      },
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Setup',
    route: '/setup',
    id: 'setup',
    icon: 'cil-settings',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'Mail Notifications',
        to: '/setup/mail_notifications',
        id: 'mailNotifications',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Document Type',
        to: '/setup/document_type',
        id: 'documentType',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Users',
        to: '/setup/userCreation',
        id: 'availableUserHeader',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Workflow',
        to: '/setup/workflow',
        id: 'workflow',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Tags',
        to: '/setup/tags',
        id: 'tags',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'set_up_bookmarks',
      //   to: '/setup/bookmarks',
      //   id: 'set_up_bookmarks',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Metadata Types',
        to: '/setup/metadatatypes',
        id: 'metadataTypes',
      }
    ],
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Reports',
    route: '/reports',
    id: 'reports',
    icon: 'cil-shield-alt',
    _children: [
      {
        _tag: 'CSidebarNavItem',
        name: 'ActivityLogs',
        to: '/reports/activity_logs/',
        id: 'ActivityLogs',
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Document Compress Log',
      //   to: '/reports/compress_log',
      //   id: 'compressLog',
      // },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Watch Folder Log',
      //   to: '/reports/watch_log',
      //   id: 'watchLog',
      // },
      {
        _tag: 'CSidebarNavItem',
        name: 'Documents Log',
        to: '/reports/documents_log',
        id: 'DocumentsLog',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Duplicate Log',
        to: '/reports/duplicate_log',
        id: 'duplicateLog',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'DocumentEvent Log',
        to: '/reports/documentevent_log',
        id: 'documenteventLog',
      },
      {
        _tag: 'CSidebarNavItem',
        name: 'Corrupt File Log',
        to: '/reports/corruptfile_log',
        id: 'corruptfileLog',
      },
    ]
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'File Explorer ',
    to: '/fileexplorer',
    icon: 'cil-file',
    id: 'file_explorer',
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Utility ',
  //   to: '/utility',
  //   icon: 'cil-shield-alt',
  //   id: 'utility',
  // },
]

import { combineReducers } from 'redux';
import loadingReducer from './loadingReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import documentTypeReducer from '../../views/pages/documentType/reducer';
import documentsReducer from './documentsReducer';
import groupsReducer from './groupsReducer';
import rolesReducer from './rolesReducer';
import workflowReducer from './workflowReducer';
import stateReducer from './stateReducer';
import transitionReducer from './transitionReducer';
import workflowDocumentTypeReducer from './workflowDocumentTypeReducer';
import eventsReducer from './eventsReducer';
import tagsReducer from './tagsReducer';
import searchReducer from './searchReducer';
import fileexplorerReducer from './fileexplorerReducer';
import setupReducer from './setupReducer';
import utilityReducer from './utilityReducer'
import checkoutReducer from './checkoutReducer';
import permissionReducers from './permissionReducers';
import languageTypeReducer from './languageTypeReducer';
import allPermissionsReducer from './allPermissionsReducer';
import subscriptionReducer from './subscriptionReducer';
import reportsReducer from '../../views/pages/reports/reducer';
import bookmarksReducer from "./bookmarksReducer";
import mailReducer from '../../views/pages/mail/reducer';
import noteSheetReducer from '../../views/pages/workflow/reducers/noteSheetReducer';
import notificationReducer from '../../views/pages/notification/reducer';
import MailNotificationReducer from './mailNotificationReducer';
import fileManagerReducer from '../../views/pages/file_explorer/redux/reducer';

const rootReducer = combineReducers({
    loadingReducer,
    uiReducer,
    userReducer,
    documentTypeReducer,
    documentsReducer,
    groupsReducer,
    rolesReducer,
    workflowReducer,
    stateReducer,
    transitionReducer,
    workflowDocumentTypeReducer,
    eventsReducer,
    tagsReducer,
    searchReducer,
    fileexplorerReducer,
    setupReducer,
    utilityReducer,
    checkoutReducer,
    permissionReducers,
    languageTypeReducer,
    allPermissionsReducer,
    subscriptionReducer,
    reportsReducer,
    bookmarksReducer,
    mailReducer,
    noteSheetReducer,
    notificationReducer,
    MailNotificationReducer,
    fileManagerReducer,
})

export default rootReducer;

import { FETCH_BOOKMARKS_SETUP } from "../action/actionTypes";

const initialState = {
    setup_bookmark_list:[]
};

const checkoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOOKMARKS_SETUP:
            return {
                ...state,
                setup_bookmark_list: action.payload
            }
        default:
            return state;
    }
};

export default checkoutReducer;

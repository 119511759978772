import { FETCH_NOTE_SHEET_DETAILS, FETCH_NOTE_SHEET_COMMENTS } from "../actionTypes/noteSheetActionTypes";
import { DEFAULT_DATA } from '../../../../library/Constants';

const initialState = {
    noteSheetDetails: DEFAULT_DATA,
    noteSheetComments: DEFAULT_DATA,

};

const noteSheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_NOTE_SHEET_DETAILS:
            return {
                ...state,
                noteSheetDetails: action.payload
            }

        case FETCH_NOTE_SHEET_COMMENTS:
            return {
                ...state,
                noteSheetComments: action.payload
            }

        default:
            return state;
    }
};

export default noteSheetReducer;

import { FETCH_DOCUMENT_FILES } from "../action/actionTypes";
import {DEFAULT_DATA} from '../../library/Constants';

const initialState = {
    fileexplore: DEFAULT_DATA
};

const fileexplorerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DOCUMENT_FILES:
            return {
                ...state,
                fileexplore: action.payload
            };
        default:
            return state;
    }
};

export default fileexplorerReducer;

import { FETCH_TAGS, DOCUMENT_ID_TAGS } from "../action/actionTypes";
import { DEFAULT_DATA } from '../../library/Constants';

const initialState = {
    tagsData: DEFAULT_DATA,
    idTagsData: DEFAULT_DATA
};

const tagsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TAGS:
            return {
                ...state,
                tagsData: action.payload
            };
        case DOCUMENT_ID_TAGS:
            return {
                ...state,
                idTagsData: action.payload
            };
        default:
            return state;
    }
};

export default tagsReducer;

import React, { Component } from 'react';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CImg } from '@coreui/react'
import CIcon from '@coreui/icons-react';
import Icon from 'react-web-vector-icons';
import { deleteUserDataFromStorage } from '../library/utils';
import { connect } from 'react-redux';
import { changeLanguage } from './changeLanguageAction'
import { fetchProfile } from '../views/pages/user/userAction';
class TheHeaderDropdown extends Component {

  componentDidMount(){
    this.props.fetchProfile(this.props.accessToken);
  }
  logout() {
    deleteUserDataFromStorage()
    window.location.reload(false);
  }

  render() {
    return (
      <CDropdown
        inNav
        className="c-header-nav-items mx-2"
        direction="down"
      >

        <CDropdownToggle className="c-header-nav-link" caret={false}>
          <div className="c-avatar">
          {(this.props.profile.image && this.props.profile.image.image) ?
            <CImg src={`${this.props.profile.image.image}`} alt='user profile' name='user' className="ci-primary"/>
            :
            <Icon name='user' font='EvilIcons' className="ci-primary" size={50} />
          }
          </div>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem
            header
            tag="div"
            color="light"
            className="text-center"
          >
            <strong>{this.props.language.account}</strong>
          </CDropdownItem>
          <CDropdownItem to='/profile/'>
            <CIcon name="cil-user" className="mfe-2" />{this.props.language.profile}
          </CDropdownItem>
          <CDropdownItem divider />
          <CDropdownItem onClick={() => this.logout()}>
            <CIcon name="cil-ban" className="mfe-2" />
            {this.props.language.logout}
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    )
  }
}

const mapStateToProps = state => {
  return {
    accessToken: state.userReducer.accessToken,
    loading: state.loadingReducer.loading,
    language: state.languageTypeReducer.language,
    profile: state.userReducer.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeLanguage: (lang) => dispatch(changeLanguage(lang)),
    fetchProfile: (accessToken) => dispatch(fetchProfile(accessToken)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TheHeaderDropdown);

import { FETCH_ALL_ROLES, FETCH_ROLES } from "../action/actionTypes";
import { DEFAULT_DATA } from '../../library/Constants';

const initialState = {
    roles: DEFAULT_DATA,
    allRoles: []
};

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ROLES:
            return {
                ...state,
                roles: action.payload
            };
        case FETCH_ALL_ROLES:
            return {
                ...state,
                allRoles: action.payload
            };
        default:
            return state;
    }
};

export default rolesReducer;

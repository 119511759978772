import { createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import rootReducer from './reducer/index';
import {AUTHORIZATION} from './action/actionTypes';
import {getUserDataFromStorage} from '../library/utils';

const persistedState = getUserDataFromStorage()
const middleware = applyMiddleware(thunk)
const store = createStore(rootReducer, middleware);
store.dispatch({ type:AUTHORIZATION, payload:persistedState})
export default store;

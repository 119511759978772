import { FETCH_MAILER_ID } from "./actionTypes";
import { DEFAULT_DATA } from '../../../library/Constants';

const initialState = {
    mailer_id: DEFAULT_DATA
};

const mailReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MAILER_ID:
            return {
                ...state,
                mailer_id: action.payload
            }
        default:
            return state;
    }
};

export default mailReducer;

import { FETCH_EVENTS, FETCH_EVENT } from "../action/actionTypes";
import { DEFAULT_DATA } from "../../library/Constants";

const initialState = {
    events: DEFAULT_DATA,
    event: DEFAULT_DATA,
};

const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS:
            return {
                ...state,
                events: action.payload
            }; 
        case FETCH_EVENT:
            return {
                ...state,
                event: action.payload
            }; 
        default:
            return state;
    }
};

export default eventsReducer;

import { REQUEST_METHOD, NOTIFICATION_COLOUR } from '../../../library/Constants';
import { setUserDataToStorage, fetchApi } from '../../../library/utils';
import { LOADING_START, LOADING_END, AUTHORIZATION } from '../../../redux/action/actionTypes';
import { setFlashNotification } from '../../../redux/action/uiAction';

const API_AUTH_TOKEN_OBTAIN = '/auth/token/obtain/?format=json'

export function userLogin(e, username, password, history) {
    e.preventDefault();
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        let body = { "username": username, "password": password }
        fetchApi(
            API_AUTH_TOKEN_OBTAIN,
            null,
            REQUEST_METHOD.POST,
            (successResponse) => {
                console.log(successResponse)
                let token = successResponse.token
                if (token === null) {
                    dispatch(userLogin(username, password, history))
                } else {
                    setUserDataToStorage(token);
                    window.location.reload(false)
                    dispatch({ type: AUTHORIZATION, payload: token });
                    history.push("/dashboard");
                    dispatch({ type: LOADING_END })
                }
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))

            }, body
        )
    };
}

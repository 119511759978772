import { HINDI } from './hindi';
import { ENGLISH } from './english';

export const LANGUAGE = [
    {
        name: 'English',
        value: ENGLISH,
        id: 'en'
    },
    {
        name: 'हिंदी',
        value: HINDI,
        id: 'hu'

    },

]
import React, { Component } from 'react';
import {
  CHeader, CToggler, CHeaderBrand, CHeaderNav, CHeaderNavItem,
  CSubheader, CBreadcrumbRouter, CLink, CAlert, CCol, CRow, CSelect
} from '@coreui/react'
import CIcon from '@coreui/icons-react';
import { connect } from 'react-redux';
import routes from '../router/routes';
import { TheHeaderDropdown, TheHeaderDropdownNotifications } from './index';
import { updateSideBar, unsetFlashNotification } from '../redux/action/uiAction';
import { LANGUAGE } from '../language/index';
import { changeLanguage } from './changeLanguageAction'
import { fetchProfile } from '../views/pages/user/userAction';
import { parseLanguage, getGroupIdFromUserProfile } from '../library/utils';
import { updateUserTimezoneAndLanguage } from '../views/pages/user/userAction';

class TheHeader extends Component {
  setLanguage(lang) {
    this.props.changeLanguage(lang)
    this.props.setTimezoneAndLanguage(this.props.accessToken, this.props.profile.id, this.props.profile.locale_profile.timezone, lang)

  }
  componentDidMount() {
    this.props.fetchProfile(this.props.accessToken);
    console.log(this.props.profile)
  }

  toggleSidebar() {
    const val = [true, 'responsive'].includes(this.props.sidebarShow) ? false : 'responsive'
    this.props.updateSideBar(val)
  }

  toggleSidebarMobile() {
    const val = [false, 'responsive'].includes(this.props.sidebarShow) ? true : 'responsive'
    this.props.updateSideBar(val)
  }

  render() {
    return (
      <CHeader withSubheader>
        <CToggler
          inHeader
          className="ml-md-3 d-lg-none"
          onClick={() => this.toggleSidebarMobile()} />
        <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={() => this.toggleSidebar()} />
        <CHeaderBrand className="mx-auto d-lg-none" to="/">
          <h3>Digi Folio</h3>
        </CHeaderBrand>

        <CHeaderNav className="d-md-down-none mr-auto">
          <CHeaderNavItem className="px-4" >
            <CBreadcrumbRouter
              className="border-0 c-subheader-nav m-0 px-0 px-md-3"
              routes={parseLanguage(routes, this.props.language)} />
          </CHeaderNavItem>

          <CHeaderNavItem className="header_userName" >
            {this.props.profile.first_name === '' || this.props.profile.last_login === '' ?
              <div></div>
              :
              <div style={{ fontWeight: "bold" }}>
                {this.props.language.welcome} <span style={{ color: "green" }}>{this.props.profile.first_name} {this.props.profile.last_name}</span>
              </div>
            }
          </CHeaderNavItem>
        </CHeaderNav>

        <CHeaderNav className="px-3">
          <label className="mr-2 mt-1"> {this.props.language.language} </label>
          <CSelect onChange={event => { this.setLanguage(event.target.value); }}>
            {
              LANGUAGE.map((data, index) => {
                return (
                  <option key={index} value={data.id} selected={this.props.profile.locale_profile !== undefined && data.id === this.props.profile.locale_profile.language ? true : false}>{data.name}</option>
                );
              })
            }
          </CSelect>
          <TheHeaderDropdownNotifications />
          <TheHeaderDropdown />
        </CHeaderNav>

        {/* <CSubheader className="px-3 justify-content-between">
          <CBreadcrumbRouter
            className="border-0 c-subheader-nav m-0 px-0 px-md-3"
            routes={parseLanguage(routes, this.props.language, getGroupIdFromUserProfile(this.props.profile))} />
          <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink
              className="c-subheader-nav-link"
              aria-current="page"
              to="/dashboard">
              <CIcon name="cil-graph" alt="Dashboard" />{this.props.language.dashboard}
            </CLink>
          </div>
        </CSubheader> */}
        {this.props.flashNotification &&

          <CCol xs="12" md="12">
            <CRow>
              <CCol xs="12" md="6"></CCol>
              <CCol xs="12" md="6">
                <CAlert className="alert-top"
                  color={this.props.flashNotification.color}
                  show={this.props.flashNotification.visible}
                  onShowChange={(count) => {
                    if (count === 0) {
                      this.props.unsetFlashMessage()
                    }
                  }}  >
                  {this.props.flashNotification.message}
                </CAlert>
              </CCol>
            </CRow>
          </CCol>
        }
      </CHeader>
    )
  }
}

const mapStateToProps = state => {
  return {
    accessToken: state.userReducer.accessToken,
    sidebarShow: state.uiReducer.sidebarShow,
    flashNotification: state.uiReducer.flashNotification,
    language: state.languageTypeReducer.language,
    profile: state.userReducer.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProfile: (accessToken) => dispatch(fetchProfile(accessToken)),
    updateSideBar: (value) => dispatch(updateSideBar(value)),
    unsetFlashMessage: () => dispatch(unsetFlashNotification()),
    changeLanguage: (lang) => dispatch(changeLanguage(lang)),
    setTimezoneAndLanguage: (accessToken, id, timezone, language) => dispatch(updateUserTimezoneAndLanguage(accessToken, id, timezone, language))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TheHeader);

import { LOADING_START, LOADING_END, FETCH_PERMISSIONS, FETCH_PERMISSIONS_FOR_ROLE } from '../../../redux/action/actionTypes';
import { fetchApi } from '../../../library/utils';
import { REQUEST_METHOD, NOTIFICATION_COLOUR } from '../../../library/Constants';
import { setFlashNotification } from '../../../redux/action/uiAction';

const API_PERMISSIONS = '/permissions/';
const API_ROLES = '/roles/';

export function getAllPermissions(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_PERMISSIONS}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_PERMISSIONS, payload: successResponse })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
    }
}

export function addPermissions(accessToken, roleId, permissionsList, roleLabel, mode) {
    let str = permissionsList[0];
    permissionsList.map((data, index) => {
        if (index > 0) {
            str = (str.concat(',')).concat(data);
        }
    })
    if(str!==undefined){
        str = str.toString()
    }

    
    let body = {
        label: roleLabel,
        permissions_pk_list: str
    }
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_ROLES}${roleId}/`,
            accessToken,
            REQUEST_METHOD.PUT,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                if (mode === 'add') {
                    dispatch(showAvailablePermissions(accessToken, roleId))
                    dispatch(setFlashNotification("Permission added Successfully", NOTIFICATION_COLOUR.SUCCESS))
                }
                else {
                    dispatch(showAvailablePermissions(accessToken, roleId))
                    dispatch(setFlashNotification("Permission removed Successfully", NOTIFICATION_COLOUR.SUCCESS))
                }
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            },
            body
        )
    }

}

export function showAvailablePermissions(accessToken, roleId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_ROLES}${roleId}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch({ type: FETCH_PERMISSIONS_FOR_ROLE, payload: successResponse })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }

        )
    }
}

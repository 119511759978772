import { LOADING_START, USER_STATES_LIST, LOADING_END, FETCH_DOCUMENT_LIST, FETCH_USERS_DOCUMENT, STATE_DOCUMENT_LIST } from '../../../../redux/action/actionTypes';
import { REQUEST_METHOD, NOTIFICATION_COLOUR, RESPONSE_TYPE } from '../../../../library/Constants';
import { setFlashNotification } from '../../../../redux/action/uiAction';
import { fetchApi } from '../../../../library/utils';

const API_WORKFLOW = '/workflows/';
const API_USER = '/User/'
const API_STATE = '/State/'
const API_DOCUMENT_LIST = '/DocumentList/'
const API_USER_STATE_LIST = '/user_state_list/'
export function fetchAllStatesAssignedToCurrentUser(accessToken, userId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_WORKFLOW}${userId}${API_USER_STATE_LIST}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: USER_STATES_LIST, payload: successResponse })
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
    }
}

export function StateDocumentList(accessToken, stateId, userId, pageNumber = 1) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_WORKFLOW.slice(0, -1)}${API_USER}${userId}${API_STATE}${stateId}${API_DOCUMENT_LIST}?page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: STATE_DOCUMENT_LIST, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
    }
}


import { SET_SIDEBAR, FLASH_NOTIFICATION } from "./actionTypes";
import { NOTIFICATION_COLOUR } from '../../library/Constants';

export function updateSideBar(value) {
    return function (dispatch) {
        dispatch({ type: SET_SIDEBAR, payload: value })
    }
}

export function setFlashNotification(message = '', color = NOTIFICATION_COLOUR.SUCCESS) {
    return function (dispatch) {
        dispatch({ type: FLASH_NOTIFICATION, payload: { message: message, color: color, visible: 5 } })
    }
}

export function unsetFlashNotification() {
    return function (dispatch) {
        dispatch({ type: FLASH_NOTIFICATION, payload: false })
    }
}

import { DEFAULT_DATA } from "../../library/Constants";
import { FETCH_SUBSCRIPTION, FETCH_SUBSCRIBED, FETCH_DOCUMENT_SUBSCRIPTION } from "../action/actionTypes";
const initialState = {
  subscriptionData: DEFAULT_DATA,
  subscribedData: DEFAULT_DATA,
  documentSubscriptionData: DEFAULT_DATA
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION:
      return {
        ...state,
        subscriptionData: action.payload
      };
    case FETCH_SUBSCRIBED:
      return {
        ...state,
        subscribedData: action.payload
      };
    case FETCH_DOCUMENT_SUBSCRIPTION:
      return {
        ...state,
        documentSubscriptionData: action.payload
      };
    default:
      return state;
  }
};

export default subscriptionReducer;

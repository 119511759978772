import { LOADING_START, LOADING_END, FETCH_SEARCH_MODELS, FETCH_SEARCH_DATA, SEARCH_FILTER_DATA, FETCH_ADVANCE_SEARCH_DATA, FETCH_SEARCH_URL, FETCH_ADVANCED_SEARCH_URL } from '../../../redux/action/actionTypes';
import { REQUEST_METHOD, DEFAULT_PAGE_NUMBER, NOTIFICATION_COLOUR, RESPONSE_TYPE } from '../../../library/Constants';
import { fetchApi, getPageNumberByItemCount } from '../../../library/utils';
import { setFlashNotification } from '../../../redux/action/uiAction';

const API_SEARCH_TEMPLATE = '/search/template';
const API_SEARCH_MODELS = '/search_models/';
const API_SEARCH_FILTERS = '/search_filters/';
const API_SEARCH_DOCUMENT = '/search/documents.Document/';
const API_ADVANCE_DOCUMENT_SEARCH = '/search/advanced/documents.Document';

export function SearchModels(accessToken) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_SEARCH_MODELS}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_SEARCH_MODELS, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}

export function SearchDataFetch(accessToken, fieldId, searchText, pageNumber = DEFAULT_PAGE_NUMBER) {
    var url = `${API_ADVANCE_DOCUMENT_SEARCH}?${fieldId}=${searchText}&page=${pageNumber}`
    if (searchText == 'all') {
        url = `${API_ADVANCE_DOCUMENT_SEARCH}?page=${pageNumber}`
    }
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            url,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_SEARCH_DATA, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}

export function searchDocDateWise(accessToken, start_date, end_date) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `/search/documents/${start_date}/${end_date}/`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_SEARCH_DATA, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            })
    };
}

export function fetchAdvanceSearch(accessToken, query, history, pageNumber = DEFAULT_PAGE_NUMBER) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        let requesturl = `${API_ADVANCE_DOCUMENT_SEARCH}?${query}&page=${pageNumber}`;
        fetchApi(
            `${API_ADVANCE_DOCUMENT_SEARCH}?${query}&page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                history.push({
                    pathname: '/advancesearchdetails/advancesearch',
                    state: query
                });
                dispatch({ type: FETCH_ADVANCE_SEARCH_DATA, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
        dispatch({ type: FETCH_ADVANCED_SEARCH_URL, payload: requesturl });
    }
}

export function fetchSearchFilters(accessToken) {
    return function (dispatch) {
        fetchApi(
            API_SEARCH_FILTERS,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: SEARCH_FILTER_DATA, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
    }
}

export function grantSearchFilter(accessToken, searchFilterId, searchModel, field, fieldLabel) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        let body = { id: searchFilterId, search_model: searchModel, field: field, field_label: fieldLabel }
        fetchApi(
            API_SEARCH_FILTERS,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch(fetchSearchFilters(accessToken))
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body
        )
    }
}

export function deleteSearchFilter(accessToken, searchId) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_SEARCH_FILTERS}${searchId}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (successResponse) => {
                dispatch(fetchSearchFilters(accessToken))
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, null, true, RESPONSE_TYPE.NULL
        )
    }
}


export function saveSearchTemplate(accessToken, name, description, search_url) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        let body = { name: name, description: description, search_url: search_url }
        fetchApi(
            API_SEARCH_TEMPLATE,
            accessToken,
            REQUEST_METHOD.POST,
            (successResponse) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification("Successfully Search Saved", NOTIFICATION_COLOUR.SUCCESS))
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body
        )
    }
}

export function fetchSearchTemplate(accessToken, pageNumber = DEFAULT_PAGE_NUMBER) {
    return function (dispatch) {
        fetchApi(
            `${API_SEARCH_TEMPLATE}?page=${pageNumber}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                dispatch({ type: FETCH_SEARCH_URL, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
    }
}

export function fetchTemplateSearch(accessToken, search_url, history) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${search_url}`,
            accessToken,
            REQUEST_METHOD.GET,
            (successResponse) => {
                history.push("/advancesearchdetails/searchtemplate");
                dispatch({ type: FETCH_ADVANCE_SEARCH_DATA, payload: successResponse });
                dispatch({ type: LOADING_END })
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }
        )
    }
}

export function UpdateSearchTemplate(accessToken, name, description, id) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        var body = { name: name, description: description };
        fetchApi(
            `${API_SEARCH_TEMPLATE}/${id}`,
            accessToken,
            REQUEST_METHOD.PATCH,
            (successResponse) => {
                dispatch(fetchSearchTemplate(accessToken));
                dispatch(setFlashNotification('Updated Successfully', NOTIFICATION_COLOUR.SUCCESS));
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, body)
    };
}

export function DeleteSearchTemplate(accessToken, id, itemCount, pageNumber) {
    return function (dispatch) {
        dispatch({ type: LOADING_START })
        fetchApi(
            `${API_SEARCH_TEMPLATE}${id}`,
            accessToken,
            REQUEST_METHOD.DELETE,
            (success) => {
                dispatch(setFlashNotification('Deleted Successfully', NOTIFICATION_COLOUR.SUCCESS));
                dispatch(fetchSearchTemplate(accessToken, getPageNumberByItemCount(itemCount, pageNumber)))
            },
            (error) => {
                dispatch({ type: LOADING_END })
                dispatch(setFlashNotification(error, NOTIFICATION_COLOUR.DANGER))
            }, null, true, RESPONSE_TYPE.NULL)
    };
}

export const ENGLISH = {
    keywords:"Keywords",
    senddocument:"Send Document",
    permissions: 'Permissions',
    roleHeader: 'Create Role',
    label: 'Label',
    department: 'Department',
    roleCreateBtn: 'Create',
    roleViewHeader: 'Roles',
    action: 'Action',
    aclBtn: 'ACL',
    chooseFileBtn: 'Choose File',
    importBtn: 'Import',
    userHeader: 'Create User',
    firstName: 'First Name',
    enterFirstName: 'Enter First Name',
    lastName: 'Last Name',
    enterLastName: 'Enter Last Name',
    email: 'Email',
    enterEmail: 'Enter Email',
    assignDepartment: 'Assign Group',
    userName: 'Username',
    enterUserName: 'Enter User Name',
    password: 'Password',
    enterPassword: 'Enter Password',
    createBtn: 'Create',
    availableUserHeader: 'Users',
    fullName: 'Name',
    lastLogin: 'Last Login',
    departmentHeader: 'Create Department',
    enterDeptName: 'Enter Department Name',
    departmentNames: 'Department Name',
    userCount: 'User Count',
    addSubDept: 'Add Sub Department',
    createDocumentTypeHeader: 'Create Document Category',
    enterDocType: 'Enter Document Category',
    availableDocTypeHeader: 'Document Categories',
    documentCount: 'Documents Count',
    metaDataTypeBtn: 'Metadata Type',
    editBtn: 'Edit',
    deleteBtn: 'Delete',
    createWorkFlowTypeHeader: 'Create WorkFlow',
    enterLabel: 'Enter Label',
    name: 'Name',
    enterName: 'Enter Name',
    availableWorkFlowHeader: 'Workflow',
    edit: 'Edit',
    deletedDateAndTime: 'Deleted Date And Time',
    availableAllLogs: 'All Logs',
    dateAndTime: 'Date And Time',
    actor: 'Actor',
    event: 'Event',
    target: 'Target',
    createTagHeader: 'Create Tags',
    color: 'Color',
    createMetadataTypesHeader: 'Create Metadata Types ',
    availableMetadataTypesHeader: 'MetadataTypes',
    fileExplore: 'File Explore',
    bulkImport: 'Bulk Import',
    queryFilter: 'Query Filter',
    rebuildSearchIndex: 'Rebuild Search Index',
    fileExploreDetails: 'File Explore Details',
    documentType: 'Document Category',
    documents: 'Documents',
    language: 'Language',
    date: 'Date',
    view: 'View',
    defaultSet: 'DefaultSet',
    images: 'Images',
    purchaseOrder: 'Purchase Order',
    technicalDocs: 'Technical Docs',
    test: 'test',
    stagingHome: 'Staging Home',
    availableSearchTypes: 'Available Search Types',
    grantBtn: 'Grant',
    removeBtn: 'Remove',
    fileElements: 'File Elements',
    fileInformation: 'File Information',
    fileName: 'File Name',
    enterFileName: 'Enter File Name',
    briefContent: 'Brief Content',
    upload: 'Upload',
    searchDocument: 'Search Document',
    enterSearchTextArea: 'Search Text Area',
    searchTextHere: 'Search Text Here',
    searchBtn: 'Search',
    advanceSearchBtn: 'Advance Search',
    searchResults: 'Search Results',
    description: 'Description',
    addedOn: 'Added On',
    allDocuments: 'All Documents',
    select: 'Select',
    tags: 'Tags',
    details: 'Details',
    download: 'Download',
    trash: 'Trash',
    submitForOcr: 'Submit for Document Content',
    pages: 'Pages',
    versions: 'Versions',
    comments: 'Comments',
    workflow: 'Workflow',
    checkout: 'Checkout',
    checkin: 'CheckIn',
    logs: 'Audit trail',
    ActivityLogs: 'Activity Logs',
    properties: 'Properties',
    acls: 'Permission management',
    createVersionHeader: 'Create Versions',
    files: 'Files',
    versionOfDocument: 'Version Of Document',
    comment: 'Comment',
    attachComment: 'Attach Comments',
    encoding: 'Encoding',
    mimeType: 'Mime Type',
    size: 'Size',
    onDate: 'On Date',
    selectTag: 'Select Tag',
    attachTag: 'Attach Tag',
    tagName: 'Tag Names',
    actionType: 'Action Type',
    addComment: 'Add Comment',
    attachCommentBtn: 'Attach Comment',
    metaDataType: 'Metadata Type',
    value: 'Value',
    initialState: 'Initial Stage',
    addToCheckoutBtn: 'Add To Checkout',
    newAclListFor: 'New Access Control List For :',
    assignRole: 'Assign Role',
    trashDocumentsHeader: 'Trash Documents',
    welcome: 'Welcome',
    recentDocumentsHeader: 'Recent Documents',
    setPassword: 'Set Password',
    restore: 'Restore',
    delete: 'Delete',
    bulkExport: 'Bulk Export',
    addTocheckout: 'Add To Checkout',
    setExpiryDateAndTime: 'Check out expiration date and time: (required)',
    timeInMins: 'Amount of time to hold the document checked out in minutes.',
    doNotallowNewVersion: 'Do not allow new version of this document to be uploaded',
    cancel: 'Cancel',
    attach: 'Attach',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    pagesForDocument: 'Pages For Document',
    add: 'Add',
    allLogs: 'All Logs',
    ocr: 'Document Content',
    state: 'Stage',
    transition: 'Transition',
    addedDate: 'Added Date',
    metaDataNameHint: 'Name used by other apps to reference this metadata type.',
    metaDataLabelHint: 'Short description of this metadata type.',
    metaDataLabelHintInHindi: 'Short description of this metadata type in hindi.',
    submitBtn: 'Submit',
    chooseRole: 'Choose Role',
    enterRoleName: 'Enter Role Name',
    chooseType: 'Choose Type',
    account: 'Account',
    profile: 'Profile',
    logout: 'Logout',
    grantPermissionTo: 'Grant Permissions to',
    grantedPermissions: 'Granted permissions',
    availablePermissions: 'Available permissions',
    aclHint: 'Select entries to be added. Hold Control to select multiple entries. Once the selection is complete, click the button below or double click the list to activate the action.',
    removeAll: 'Remove All',
    confirm: 'Confirm',
    yes: 'Yes',
    editRoleName: 'Edit Roles Name',
    editDepartmentName: 'Edit Department Name',
    closeBtn: 'Close',
    updateBtn: 'Update',
    enterGroupName: 'Enter Group Name',
    preview: 'Preview',
    pageNumber: 'Page Number',
    rebuildCache: 'Rebuild Cache',
    editVersions: 'Edit Versions',
    versionComment: 'Versions Comment',
    groupName: 'Groups Name...',
    file: 'files',
    uploadAFile: 'Upload a file',
    editDocType: 'Assign Document Type',
    completion: 'Completion',
    initial: 'Initial',
    states: 'Stages',
    formChechLabel: 'form-check-label',
    enterCompletion: 'Enter Completion',
    createState: 'Create Stage',
    updateTag: 'Upadate Tag',
    editUser: 'Edit User',
    originState: 'Origin Stage',
    selectOriginState: 'Please select Origin Stage',
    destinationState: 'Destination Stage',
    condition: 'Condition',
    newPassword: 'New Password',
    uploadBtn: 'Upload',
    stagingFolder: 'Staging Folder',
    staging: 'Staging',
    createTransition: 'Create Transition',
    eventSubsciptions: 'Event subscriptions',
    nameSpace: 'Namespace',
    subscription: 'Event Notification',
    filter: 'Filter',
    chooseTransition: 'Choose Transition',
    user: 'User',
    searchUser: 'Search User',
    profileDetails: 'Profile Details',
    admin: 'Admin',
    changePassword: 'Change Password',
    oldPassword: 'Old Password',
    enterOldPassword: 'Enter Old Password',
    enterNewPassword: 'Enter New Password ',
    confirmPassword: 'Confirm Password',
    enterConfirmPassword: 'Enter Confirm Password',
    updateTimezoneAndLanguage: 'Update Timezone And Language',
    timezone: 'Timezone',
    assign: 'Assign ',
    filterOptions: 'Filter  Options',
    grantedSearchTypes: 'Granted Search Types',
    newAclFor: 'New access control lists for',
    utilities: 'Utilities',
    addMetadataType: 'Add Metadata Types',
    metadataTypes: 'Metadata Types',
    chooseMetaDataType: 'choose metadata type',
    required: 'Required',
    dashboard: 'Dashboard',
    utility: 'Utility',
    setup: 'Setup',
    knowledgeRepository: 'Knowledge Repository',
    workArea: 'WorkArea',
    search: 'Search',
    assigneddocuments: 'Assigned Documents',
    checkouts: 'Checkouts',
    checkoutsDetails: 'Checkouts Details',
    leave: 'Leave',
    propertiesForDocument: 'Properties For Document',
    dateAdded: 'Date added',
    uuid: 'UUID',
    fileMimeType: 'File mimetype',
    fileEncodeing: 'File encoding',
    fileSize: 'File size',
    filePathInStorage: 'File path in storage',
    checksum: 'Checksum',
    pleaseWait: 'Please Wait..',
    savesearch: 'Save Search',
    searchTemplate: 'Search Template',
    notifications: 'Notifications',
    downloadCsvButton: 'Download Example CSV',
    areYouSure: 'Are you sure you want to perform this action ?',
    departments: 'Departments',
    trashedDocuments: 'Trashed Documents',
    workFlowDocumentType: 'Workflow Document Type',
    createWorkflowDocumentType: 'Create Workflow DocumentType',
    pleaseAssignDocumentType: 'Please Assign Document Type',
    trashTimeUnit: 'Trash Time Unit:',
    trashTimePeriod: 'Trash Time Period:',
    deleteTimeUnit: 'Delete time Unit:',
    deleteTimePeriod: 'Delete Time Period:',
    enterTrashTime: 'Enter Trash Time',
    enterDeleteTime: 'Enter Delete Time',
    selectDeleteTimeUnit: 'Select Delete Time Unit',
    selectTrashTimeUnit: 'Select Trash Time Unit',
    SelectFieldType: 'Select Field Type',
    ProvideFieldoptions: 'Provide Field options',
    AddMoreOptions: 'Add More Options',
    Hindi: 'Hindi',
    note: '* Note -: If you are Choosing TextBox then No value Field Will be considered',
    Seen: 'Mark As Read',
    checkoutDateTime: 'Checkedout Date and Time',
    expiryDateTime: 'Expiry Date and Time',
    blockNewVersion: 'Block New Version Upload',
    enterNumber: 'Enter Number Here',
    events: 'Events',
    userAcls: 'User Acls',
    documentDetails: 'Document Details',
    tagNames: 'Tag Names',
    movetrash: 'Move to trash',
    identifier: 'Identifier',
    currentstage: 'Currentstage',
    LastTransition: 'LastTransition',
    ShareLink: 'Share Link',
    addAssign: 'Add To Assign',
    readyAssign: 'Ready For Assign',
    selectfile: 'Select a file to upload',
    or: 'Or',
    drag: 'Drag a file into this box',
    recordmanagement: 'Record Management',
    documentlogs: 'Document Logs',
    complete: 'Already Completed',
    enterComment: 'Enter Comment',
    bookmarks: 'Bookmarks',
    selectDepartment: 'Select Group',
    stateDocuments: 'Stage Documents',
    chooseState: 'Choose Stage',
    stateDocList: 'List All Document in this Stage',
    usersFailedToImport: 'List Of user failed To Import',
    errorMsg: 'Error Message',
    sharedLink: 'Sharedlink',
    createSharedLink: 'Create Sharedlink',
    availableSharedLink: 'Available Sharedlink ',
    startTime: 'Start Time',
    endTime: 'End Time',
    editShareLink: 'Edit Sharedlink',
    storageUsage: 'Storage Usage',
    compressLog: "Compression Log",
    reports: "Reports",
    originalFileSize: "Original File Size",
    compressedFileSize: "compressed File Size",
    compressionCompletedOn: "compression Completed On",
    employeeId: "Employee ID",
    groups: "Groups",
    group: "Group",
    createGroup: "Create Group",
    profilePicture: "Profile picture",
    uploadProfilePicture: "Upload profile picture",
    metaData: "Additional data",
    watchLog: "Watch Folder Log",
    duplicate: "Duplicate",
    DocumentsLog: "Documents Log",
    duplicateLog: "Duplicate Log",
    documenteventLog: "Document Event Log",
    documentStats: "Last 7 Days Documents Stats",
    freeSpace: "Free Space",
    used: "Used",
    corruptfileLog: "Corrupt File Log",
    designation: "Designation",
    signature: "Signature",
    UpdateSignature: "Update Signature",
    UploadSignature: "Upload Signature",
    UploadSignAndProfilePic: "Upload Signature And Profile Picture",
    set_up_bookmarks: "Bookmarks",
    attachDocuments: "Attach Documents",
    proceedWithSignature: "Proceed with Signature",
    proceedWithoutSignature: "Proceed without Signature",
    assignedDocumentType: "Assigned document type",
    attachFile: "Append file",
    file_explorer: "File explorer",
    mailNotifications: "Mail notifications",
    email_profile: "Email profile",
    to: "To",
    email_body: "Email body",
    time: "Time",
    operator: "Operator",
    subject: "Subject",
    mailNotifications: "Mail notifications",
}
